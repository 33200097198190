.c-starrate {
    @include clearfix;
    &__sati {
        @include logo-svg($logo-icon_star, 64px, 12px);
        position: relative;
        float: left;
        overflow: hidden;
        margin-right: 4px;
        color: transparent;
        font-size: 10px;
    }
    &__gauge {
        @include logo-svg($logo-icon_star_fill, 64px, 12px);
        position: absolute;
        top: 0;
        left: 0;
    }
    &__review {
        color: $color-gray-04;
        float: left;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 1.1;
    }
    dt {
        @include sr-only;
    }
    &.c-starrate--amazon {
        dt {
            @include sr-only-reset;
            @include logo-svg($logo-amazon-icon, 14px, 14px);
            overflow: hidden;
            float: left;
            margin-right: 4px;
            color: transparent;
        }
    }
    &__sati-text {
        display: inline-block;
        font-size: 15px;
        font-weight: bold;
        color: $color-gray-03;
        line-height: 1.1;
        align-self: flex-end;
    }
}
