.c-list {
    background: $color-gray-14;
    width: 100%;

    &__item {
        color: $color-gray-03;
        font-size: 15px;

        &-link {
            @include text-ellipsis;

            display: block;
            padding: 0 16px;
            height: 40px;
            line-height: 40px;
            color: $color-gray-03;

            .c-list__number {
                @include size(24px 24px);

                display: inline-block;
                line-height: 24px;
                font-size: 13px;
            }

            .c-list__icon {
                @include size(24px 24px);

                display: inline-block;
                margin: -1px 4px 1px 0;
                vertical-align: middle;

                img {
                    display: block;
                    @include size(24px 24px);
                }
            }
        }
    }

    &__text {
        &-sub {
            float: right;
            padding-left: 10px;
            font-size: 13px;
            color: #999;
        }
    }

    &.c-list--border {
        .c-list__item {
            border-bottom: 1px solid $color-gray-12;
        }
    }

    &.c-list--arrow {
        .c-list__item-link {
            position: relative;
            padding-right: 34px;

            &:after {
                @include rotate(45deg);

                @include size(8px);

                content: "";
                position: absolute;
                top: 52%;
                right: 16px;
                margin: -5px 0 0 -5px;
                border: 1px solid #999;
                border-width: 1px 1px 0 0;
                margin-left: -8px;
            }
        }
    }

    &.c-list__gallery {
        overflow: hidden;
        margin-bottom: -1px;

        .c-list__item {
            box-sizing: border-box;
            width: 50%;
            float: left;
            border-right: 1px solid $color-gray-12;

            &:nth-child(even) {
                border-right: none;
            }
            @include mq-640 {
                width: 25%;
                &:nth-child(even) {
                    border-right: 1px solid $color-gray-12;
                }
            }
        }
    }
}
