.c-tag {
    display: inline-block;
    position: relative;
    height: 32px;
    border: 1px solid $color-gray-10;
    border-radius: 16px;
    background: $color-gray-14;
    padding: 0 12px;
    margin: 0 3px 8px 0;
    font-size: 14px;
    font-weight: normal;
    color: $color-gray-03;
    line-height: 32px;
    white-space: nowrap;
    box-sizing: border-box;

    &.selected {
        padding-right: 30px;
        border-color: #e4e4e4;
        background-color: #fff;
        color: #333;
        white-space: nowrap;
    }

    &--selected,
    &.active {
        border-color: rgba($color: $color-11st-red, $alpha: 0.2);
        background-color: rgba($color: $color-11st-red, $alpha: 0.02);
        font-weight: bold;
        color: $color-11st-red;
    }

    &[disabled],
    &--disabled,
    &.c-tag--disabled {
        border-color: $color-gray-11;
        color: $color-gray-09;

        .delete {
            &:before,
            &:after {
                background-color: $color-gray-07;
                opacity: 0.2;
            }
        }
    }

    .delete {
        position: absolute;
        right: 0;
        top: 0;
        width: 28px;
        height: 30px;
        border: 0;
        border-radius: 50%;
        background-color: transparent;
        vertical-align: top;

        &:before,
        &:after {
            position: absolute;
            left: auto;
            right: 50%;
            top: 50%;
            width: 1px;
            height: 12px;
            margin: -6px 0 0 -1px;
            background: $color-gray-07;
            transform: rotate(45deg);
            content: "";
        }

        &:after {
            transform: rotate(-45deg);
        }
    }
}
