@mixin sprite-retina-width($sprite) {
    width: nth($sprite, 5) / 2;
}

@mixin sprite-retina-height($sprite) {
    height: nth($sprite, 6) / 2;
}

@mixin sprite-retina-position($sprite, $type: null) {
    $sprite-offset-x: nth($sprite, 3) / 2;
    $sprite-offset-y: nth($sprite, 4) / 2;

    @if $type == null {
        background-position: $sprite-offset-x $sprite-offset-y;
    } @else if $type == "mask" {
        mask-position: $sprite-offset-x $sprite-offset-y;
    }
}

@mixin sprite-retina-image($sprite, $type: null) {
    $sprite-image: nth($sprite, 9);

    @if $type == null {
        background-image: url("#{$sprite-image}");
    } @else if $type == "mask" {
        mask-image: url("#{$sprite-image}");
    }
}

@mixin sprite-retina-size($sprite, $type: null) {
    $sprite-sheet-width: nth($sprite, 7) / 2;
    $sprite-sheet-height: nth($sprite, 8) / 2;

    @if $type == null {
        background-size: $sprite-sheet-width $sprite-sheet-height;
    } @else if $type == "mask" {
        mask-size: $sprite-sheet-width $sprite-sheet-height;
    }
}

@mixin sprite-retina($sprite, $type: null) {
    @include sprite-retina-image($sprite, $type);

    @include sprite-retina-position($sprite, $type);

    @include sprite-retina-width($sprite);

    @include sprite-retina-height($sprite);

    @include sprite-retina-size($sprite, $type);
}
