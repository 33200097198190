@import "../../common/sprites/sp_sns_share2";

.b-layer__modal {
    display: none;
    overflow: hidden;
    position: fixed;
    width: 100%;
    max-width: 1280px;
    padding-top: 56px;
    background-color: $color-gray-14;
    z-index: 102;
    box-sizing: border-box;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    box-shadow: 0 -2px 5px 2px rgba(0, 0, 0, 0.15);

    &-title {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 56px;
        padding: 0 16px;
        color: $color-gray-01;
        font-size: 19px;
        line-height: 58px;
        text-align: center;
        box-sizing: border-box;

        &.b-layer__modal-left {
            text-align: left;
        }
    }

    &-content {
        overflow: hidden;
        max-height: calc(100vh - 257px);
        padding: 16px;
    }

    &-close {
        position: absolute;
        top: 8px;
        right: 9px;
        width: 44px;
        height: 44px;
        background: none;
        border: none;
        color: transparent;

        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 3px;
            height: 50px;
            margin: -27px 0 0 -1px;
            background: $color-gray-03;
        }

        &:before {
            transform: scale(0.5) rotate(45deg);
        }

        &:after {
            transform: scale(0.5) rotate(-45deg);
        }
    }

    &-holder {
        @include size(100% 16px);
        display: block;
        position: relative;
        z-index: 2;
        background-color: $color-gray-14;
        cursor: row-resize;

        &:before {
            @include size(48px 4px);
            position: absolute;
            top: 6px;
            left: 50%;
            border-radius: 2px;
            background-color: $color-gray-10;
            box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.05);
            transform: translateX(-50%);
            content: "";
        }
    }

    .c-popup-share {
        background: $color-gray-14;
        padding: 0;

        &__list {
            display: table;
            width: 100%;
            margin: -16px 0 -4px;
            padding: 0;
            box-sizing: border-box;

            li {
                display: inline-table;
                width: 25%;
                height: auto;
                font-size: 13px;
                color: $color-gray-04;
                letter-spacing: $body-spacing;
            }
        }

        &__service {
            display: block;
            margin: 12px auto;
            padding: 0;
            border: 0;
            background: none;
            font-size: 13px;
            color: $color-gray-03;
            text-align: center;
            outline: none;

            &:before {
                display: block;
                margin: 0 auto 7px;
                content: "";
            }

            &.kakao:before {
                @include sprite-retina($sp_sns_share2_kakao);
            }

            &.kakaostory:before {
                @include sprite-retina($sp_sns_share2_kakaostory);
            }

            &.line:before {
                @include sprite-retina($sp_sns_share2_line);
            }

            &.band:before {
                @include sprite-retina($sp_sns_share2_band);
            }

            &.twitter:before {
                @include sprite-retina($sp_sns_share2_twitter);
            }

            &.facebook:before {
                @include sprite-retina($sp_sns_share2_facebook);
            }

            &.sms:before {
                @include sprite-retina($sp_sns_share2_sms);
            }

            &.url:before {
                @include sprite-retina($sp_sns_share2_url);
            }

            &--x:before {
                @include sprite-retina($sp_sns_share2_x);
            }
        }
    }

    &--initial {
        overflow-y: auto;
        max-height: 100%;
        padding-top: 16px;
        .b-layer__modal-content {
            max-height: none;
            padding: 0;
        }
        .b-layer__modal-close {
            top: 20px;
            right: 16px;
            width: 36px;
            height: 36px;
            &:before,
            &:after {
                border-radius: 3px;
            }
        }
    }

    &.share-sns {
        letter-spacing: 0;

        .b-layer__modal-title {
            top: 0;
        }

        .b-layer__modal-content {
            max-height: calc(100vh - 56px); // 브라우저 높이 - 타이틀 영역 높이
        }

        .c-nudge {
            position: relative;
            margin: -8px 0 28px;
            padding: 20px 18px;
            background-color: #fffbed;
            text-align: left;
            &__text {
                font-size: 15px;
                color: $color-gray-02;
                line-height: 22px;
                word-break: keep-all;
                strong {
                    &:last-of-type {
                        margin-right: 2px;
                    }
                }
                span {
                    color: $color-11st-blue;
                }
                // MPSR-143248 pdp 구조 변경 후 제거
                em:not(.c-nudge__subtext) {
                    display: inline-block;
                    font-size: 13px;
                    color: $color-gray-05;
                }
            }
            &__subtext {
                display: block;
                margin-top: 4px;
                font-size: 13px;
                color: $color-gray-02;
                line-height: 19px;
            }
            .c-tooltip {
                display: inline-block;
                position: relative;
                margin-top: 9px;
                &:before {
                    left: 32px;
                }
            }
            &__notice {
                margin-top: 8px;
                font-size: 13px;
                color: $color-gray-04;
                letter-spacing: -0.5px;
                button {
                    text-decoration: underline;
                    border: 0;
                    background: none;
                    color: $color-gray-04;
                }
                &[type="button"] {
                    display: block;
                    border: 0;
                    background: none;
                    font-size: 12px;
                    line-height: 17px;
                    &:after {
                        @include ico-arrow-right(5.5px, $color-gray-04);
                        display: inline-block;
                        vertical-align: middle;
                        margin: -1px 0 1px 2px;
                    }
                }
            }
        }
    }
    &.quick-view {
        .b-layer__modal-title {
            padding: 0 60px;
            margin: 0;
            border-bottom: 1px solid $color-gray-11;

            span {
                vertical-align: top;
            }
            .ellipsis {
                display: inline-block;
                max-width: 100%;
                margin-right: 3px;
            }
        }
        .b-layer__modal-content {
            padding: 0;
        }
        .l-grid {
            margin-top: 15px;
        }
        .s-list {
            padding-bottom: 16px;
        }
    }
    .c-popup-agree {
        padding: 28px 24px 16px;
        &__title {
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            color: $color-gray-02;
            text-align: center;
        }
        &__desc {
            margin-top: 7px;
            font-size: 14px;
            line-height: 24px;
            color: $color-gray-05;
            text-align: center;
        }
        &__terms {
            margin-top: 24px;
            padding: 20px 0;
            border: 1px solid #eaeaea;
            border-width: 1px 0;
        }
        &__terms-item {
            overflow: hidden;
            font-size: 14px;
            line-height: 20px;
            &:not(:first-of-type) {
                margin-top: 14px;
            }
            dt {
                float: left;
                margin-right: 6px;
                font-weight: 700;
                color: $color-gray-03;
            }
            dd {
                color: $color-gray-04;
            }
        }
        &__text {
            margin: 12px 23px 0 0;
            font-size: 13px;
            line-height: 17px;
            color: $color-gray-05;
        }
        &__text-point {
            font-weight: 700;
            color: $color-11st-red;
        }
        &__buttons {
            margin-top: 23px;
            .c-button {
                width: 100%;
                height: 52px;
                line-height: 52px;
            }
        }
    }
    &--alarmon {
        .c-popup__content {
            margin: 0;
            padding: 28px 24px 16px;
        }
        .c-popup__content-info {
            font-size: 20px;
            line-height: 28px;
        }
        .c-popup__content-point {
            @include text-ellipsis;
            display: inline-block;
            max-width: 166px;
            margin-right: 4px;
            vertical-align: top;
        }
        .c-popup__text {
            margin-top: 8px;
            color: $color-gray-03;
        }
        .c-popup__subtext {
            margin-top: 8px;
            color: $color-gray-04;
        }
        .c-popup__button {
            height: 52px;
            margin-top: 28px;
            background-color: $color-11st-red;
            font-size: 16px;
            color: $color-gray-14;
            font-weight: bold;
            line-height: 52px;
        }
        .b-layer__modal-close:before, .b-layer__modal-close:after {
            height: 44px;
        }
    }
}

.c-button {
    &--share {
        @include size(32px);

        overflow: hidden;
        position: relative;
        padding: 0;
        margin: 0;
        border: 1px solid $color-gray-10;
        border-radius: 24px;
        background: $color-gray-14;
        line-height: 32px;
        outline: none;
        color: transparent;

        &[type="button"] {
            border-radius: 24px;
        }

        &:after {
            @include button-share;
            position: absolute;
        }
    }
}

// overflow modal 내 레이어
.c-nudge-layer {
    display: none;
    position: absolute;
    top: 0;
    left: -8px;
    right: -8px;
    background: $color-gray-14;
    border: 1px solid $color-gray-10;
    &__title {
        display: block;
        padding: 12px 16px;
        border-bottom: 1px solid $color-gray-12;
        color: $color-gray-03;
        font-size: 16px;
        font-weight: normal;
        line-height: 20px;
    }
    &__content {
        padding: 16px;
    }
    &__table {
        width: 100%;
        margin-top: 10px;
        border: 1px solid $color-gray-11;
        border-top: 0;
        table-layout: fixed;
        th,
        td {
            padding: 8px;
            font-size: 14px;
            color: $color-gray-04;
            font-weight: normal;
            border-top: 1px solid $color-gray-11;
        }
        th {
            white-space: nowrap;
        }
        td {
            border-left: 1px solid $color-gray-11;
        }
    }
    &__text {
        font-size: 14px;
        color: $color-gray-04;
        &:not(&:first-of-type) {
            margin-top: 10px;
        }
    }
    &__close {
        position: absolute;
        overflow: hidden;
        top: 1px;
        right: 8px;
        width: 48px;
        height: 44px;
        padding: 0;
        border: none;
        background: none;
        text-indent: -10000px;

        &:before,
        &:after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            width: 1px;
            height: 22px;
            margin: -11px 0 0 0;
            background: #333;
            transform: rotate(45deg);
        }
        &:after {
            width: 22px;
            height: 1px;
            margin: 0 0 0 -11px;
        }
    }
}
