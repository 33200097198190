.c-card-review__product {
    @include rounding-box;
    overflow: hidden;
    position: relative;
    margin: 0 16px 16px;
    padding: 12px;
    border-radius: 4px;
    letter-spacing: $body-spacing;

    .c-card {
        display: table;
        width: 100%;

        &__thumb {
            @include size(56px);
            display: table-cell;

            img {
                z-index: 1;
            }
        }
        &__info {
            display: table-cell;
            padding: 0 0 0 16px;
            vertical-align: middle;
        }
        &__title {
            display: block;
            overflow: hidden;
            height: 17px;
            font-size: $font-size-body1;
            font-weight: bold;
            color: $color-gray-02;
            line-height: 17px;
            white-space: pre-line;
        }
        &__option {
            display: block;
            overflow: hidden;
            max-height: 32px;
            margin-top: 4px;
            font-size: $font-size-body2;
            color: $color-gray-06;
            line-height: 16px;
        }
    }
}
