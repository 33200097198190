[data-type="Tabs_ImgText_Col2"] {
    .c-tab {
        padding-top: 0;
        .c-tab__list {
            table-layout: fixed;
            .c-tab__item {
                border: 0;
                border-right: 1px solid #f4f4f4;
                &:last-child {
                    border-right: 0;
                }
            }
            .c-tab__link {
                @include vertical-middle(40px);
                margin: 0;
                background: #fff;
                border: 0;
                .c-tab__link-icon {
                    img {
                        @include size(24px);
                        margin-right: 6px;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}
