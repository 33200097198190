@import "../../common/sprites/sp_eui";

.c-flag__item {
    float: left;
    margin: 0 4px 4px 0;
    @include icon-flag("c-flag__item--");
}

.c-flag {
    display: block;
    overflow: hidden;
    height: 22px;

    &:after {
        content: "";
        display: block;
        clear: both;
    }
    dt {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
        font-size: 6px;
        line-height: 1;
        white-space: nowrap;
    }
}

.c-flag__item--11festa {
    color: $color-gray-14;
    border-color: rgba($color: $color-11st-red, $alpha: 0.9);
    background-color: rgba($color: $color-11st-red, $alpha: 0.9);
}

.c-flag__item--gradient {
    color: $color-gray-14;
    border: 0;
    background: linear-gradient(to right, #1367ff 0%, #00dbdf 100%);
    line-height: 16px;
}

.c-flag__item--gradient-red {
    color: $color-gray-14;
    border: 0;
    background: linear-gradient(to right, #ff4466 0%, $color-11st-gradient-pink 100%);
    line-height: 16px;
}

.c-flag__item--choice {
    color: $color-gray-14;
    border-color: #232f3e;
    background: #232f3e;
}

.c-flag__item--11festival {
    color: $color-11st-red;
    border-color: rgba($color: $color-11st-red, $alpha: 0.4);
    background-color: rgb(255, 247, 248);
}

.c-flag__item--image {
    display: flex;
    align-items: center;
    padding: 0;
    border: 0;
    background-color: transparent;

    img {
        display: block;
        height: 100%;
    }
}

// 로고 타입
.c-flag__item--ooah,
.c-flag__item--freshtable,
.c-flag__item--ootd {
    padding: 0;
    border: none;
    color: transparent;
}

// 명품개런티
.c-flag__item--ooah {
    @include get-sprite-common("logo_ooah", 36);
}

// 신선밥상
.c-flag__item--freshtable {
    @include get-sprite-common("logo_freshtable", 52);
}

// OOTD 오오티디
.c-flag__item--ootd {
    @include get-sprite-common("logo_ootd", 50);
}

.c-flag-box {
    overflow: hidden;
    height: 22px;

    dt {
        @include sr-only;
    }
    .c-flag {
        overflow: initial;
        height: auto;
    }
}
