.c-searchbox {
    position: relative;
    padding-right: 54px;
    height: 36px;
    min-width: 304px;

    .c-input {
        height: 34px;
        line-height: 34px;
        padding-right: 60px;
        border: 1px solid $color-gray-09;
        font-size: 15px;
    }

    &__button {
        @include size(56px 34px);

        position: absolute;
        right: 8px;
        top: 0;
        background: #444;
        border: 1px solid $color-gray-09;
        border-left: 0;
        border-radius: 0 2px 2px 0;
        color: $color-gray-14;
        font-size: 14px;
    }

    &--bgcolor {
        padding: 0 8px;
        background: #e3e3e8;
    }
}
