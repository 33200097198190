.c-dialog {
    display: none;
    overflow: hidden;
    position: fixed;
    left: 50%;
    top: 50%;
    width: 280px;
    border-radius: 8px;
    background: #fff;
    z-index: 102;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.36), 0px 6px 24px -8px rgba(0, 0, 0, 0.12);

    &__title {
        background: #fff;
        color: $color-gray-03;
        font-size: 17px;
        padding: 0 16px;
        height: 51px;
        line-height: 51px;
        border-bottom: 1px solid $color-gray-11;
        text-align: center;
    }

    &__items {
        overflow: hidden;
        position: relative;
        max-height: 272px;

        ul {
            li {
                position: relative;
                min-height: 48px;
                padding: 0 16px;
                border-top: 1px solid $color-gray-13;
                font-size: 15px;
                color: $color-gray-03;
                line-height: 48px;

                &:first-child {
                    border-top: 0;
                }
                .c-radio + label {
                    height: 48px;
                    line-height: 48px;

                    &:before {
                        top: 12px;
                    }

                    &:after {
                        top: 18px;
                    }
                }

                .c-dialog__item-info {
                    margin-top: -10px;
                    padding: 0 0 16px 30px;
                    font-size: 13px;
                    color: #999;
                    line-height: 18px;
                }

                .c-iconinfo {
                    position: absolute;
                    top: 11px;
                    left: 140px;
                }
            }
        }

        &:after {
            position: absolute;
            right: 0;
            left: 0;
            bottom: 0;
            height: 18px;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
            content: "";
        }
        .w-scroll-css {
            overflow-y: auto;
            max-height: 272px;
            overscroll-behavior: contain;
            -webkit-overflow-scrolling: touch;
        }
    }

    &__buttons {
        @include size(100% 48px);
        margin: 0;
        padding: 0;
        border-top: 1px solid $color-gray-12;

        button {
            @include size(50% 48px);
            display: block;
            background: $color-gray-14;
            float: left;
            padding: 0;
            border: 0;
            border-right: 1px solid $color-gray-13;
            font-size: 16px;
            color: $color-gray-03;

            &:last-child {
                border-right: none;
                color: $color-11st-red;
            }
        }

        &--color {
            button {
                &:last-child {
                    color: $color-gray-03;
                }
            }
        }
    }

    &__text {
        text-align: center;
        padding: 24px 8px;
        font-size: 15px;
        color: $color-gray-02;
    }

    &__linkList {
        display: none;

        .c-dialog__title {
            @include sr-only;
        }

        .c-dialog__items {
            ul {
                margin: 0;

                li {
                    border-bottom: 1px solid #eaeaea;
                    font-size: 14px;
                    line-height: 30px;
                    min-height: 30px;

                    @include mq-360 {
                        line-height: 42px;
                        min-height: 42px;
                    }

                    a {
                        display: block;
                        padding-left: 16px;
                    }

                    &.on {
                        a {
                            padding-right: 20px;
                            color: #f00;
                            position: relative;
                            @include ico-check(#f00);
                        }
                    }

                    &:last-child {
                        border-bottom: 0;
                    }
                }
            }
        }

        .c-dialog__buttons {
            position: static;
            height: 36px;

            @include mq-360 {
                height: 48px;
            }

            button {
                width: 100%;
                height: 36px;

                @include mq-360 {
                    height: 48px;
                }

                &:last-child {
                    color: #333;
                }
            }
        }
    }

    &__close {
        position: absolute;
        top: 8px;
        right: 8px;
        border: 0;
        margin: 0;
        padding: 0;
        display: block;
        width: 28px;
        height: 28px;
        text-indent: -99999px;
        background-color: #fff;

        &:before,
        &:after {
            content: "";
            display: block;
            position: absolute;
            right: 13px;
            top: 21%;
            width: 1px;
            height: 20px;
            background-color: #111;
            background-image: none;
        }

        &:before {
            transform: rotate(224deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }

    &.c-dialog__sorting {
        margin-left: 0;
        margin-top: 0;
        height: initial;
    }

    &.c-dialog__notice {
        min-height: 87px;
        margin-left: 0;
    }

    &.c-dialog__mileage {
        .c-dialog__text {
            margin-top: 0;
            padding: 16px;
            text-align: left;
            p {
                padding: 8px 0;
            }
            label {
                display: inline-block;
                margin-top: 8px;
            }
        }
    }

    &.c-dialog--autoheight {
        padding-bottom: 0;

        .c-dialog__items {
            height: auto;

            &:after {
                display: none;
            }
        }
    }

    &[tabindex]:focus {
        outline: 0;
    }
}

// 자간 이슈로 인한 임시 처리
.android {
    &.mobile {
        .c-dialog__items ul li .c-iconinfo {
            left: 147px;
        }
    }
}
