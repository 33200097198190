@import "../../pui/sprites/sp_icons";
@import "../../common/sprites/sp_eui";

.c-headline {
    background: $color-gray-14;
    display: block;
    position: relative;
    padding: 0;
    width: auto;
    text-align: left;
    &__text {
        display: block;
        position: relative;

        .c-ad__btn {
            position: absolute;
            top: 50%;
            right: 8px;
            margin-top: -9px;
        }
        .c-headline__close {
            position: absolute;
            top: 0px;
            right: 0px;
            display: block;
            width: 55px;
            height: 55px;
            color: transparent;
            font-size: 1px;
            &:before,
            &:after {
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                width: 3px;
                height: 48px;
                //margin-top: -12px;
                background-color: #333;
                transform: translateY(-50%) scale(0.5) rotate(45deg);
            }

            &:after {
                transform: translateY(-50%) scale(0.5) rotate(-45deg);
            }
        }
    }
    &__link {
        display: block;
        position: relative;
        &:after {
            @include rotate(45deg);
            @include size(9px);
            content: "";
            position: absolute;
            top: 50%;
            right: 16px;
            margin-top: -4px;
            border: solid #999;
            border-width: 1px 1px 0 0;
        }
        .c-headline__txtlink {
            color: $color-gray-07;
            line-height: 0;
            position: absolute;
            font-size: 13px;
            top: 51%;
            right: 30px;
        }
        .c-headline__iconlink {
            display: block;
            position: absolute;
            top: 18px;
            right: 17px;
            max-width: 64px;
            max-height: 24px;
            padding-right: 10px;
            img {
                display: block;
                width: 100%;
                max-height: 24px;
            }
        }
    }
    // React 버전으로 변경 후 사용하지 않음
    &__img {
        padding: 0;
        min-height: 48px;
        overflow: hidden;
        img {
            display: block;
            margin: 0 auto;
            &.c-headline__skd {
                margin: 6px auto 0 auto;
            }
        }
        &.c-headline--flexible {
            img {
                width: 100%;
            }
        }
    }
    &__title {
        color: $color-gray-02;
        display: block;
        position: relative;
        height: 60px;
        padding: 11px 16px;
        white-space: nowrap;
        font-weight: bold;
        font-size: 18px;
        line-height: 40px;
        overflow: hidden;
        box-sizing: border-box;
        img {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 300px;
            height: 44px;
        }
        strong + span {
            margin-left: 4px;
            font-size: 15px;
        }
        small {
            margin-left: 4px;
            font-size: 12px;
            color: #666;
        }
    }
    &__subtext {
        color: $color-gray-04;
        display: block;
        margin-top: -20px;
        padding: 4px 16px 14px;
        overflow: hidden;
        white-space: nowrap;
        font-size: 12px;
        line-height: 18px;
    }
    &__tab {
        @include size(100% 43px);
        display: table;
        position: relative;
        z-index: 2;
        li {
            display: table-cell;
            text-align: center;
            white-space: nowrap;
            height: 43px;
            font-size: 15px;
            a {
                display: block;
                position: relative;
                padding: 2px 8px;
                line-height: 39px;
                color: $color-gray-02;
                &:after {
                    @include size(100% 2px);
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    content: "";
                }
            }
            &.on {
                a {
                    color: $color-11st-red;
                    &:after {
                        background-color: $color-11st-red;
                    }
                }
            }
        }
        &--col2 li {
            width: 50%;
        }
        &--col3 li {
            width: 33.33%;
        }
        &--col4 li {
            width: 25%;
        }
        &--col5 li {
            width: 20%;
        }
    }
    &__infoToggle {
        display: block;
        position: absolute;
        right: 8px;
        top: 50%;
        margin-top: -16px;
    }
    &__toggleBtn {
        display: block;
        @include sprite-retina($sp_icons_info);
        span {
            @include sr-only;
        }
    }
    &__infoTxt {
        position: absolute;
        z-index: 30;
        top: 28px;
        right: 8px;
        width: 240px;
        padding: 12px 28px 12px 12px;
        border: 1px solid #333;
        background-color: #fff;
        display: none;
        &.toggle-show {
            display: block;
        }
        p {
            font-size: 13px;
            color: #333;
        }
        .close {
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            background-color: #fff;
            border: 0;
            @include size(28px);
            @include btn-close-x(11px, 1px, #333);
            span {
                @include sr-only;
            }
        }
    }
    &__infoBtn {
        @include sprite-retina($sp_eui_info2);

        position: absolute;
        top: 50%;
        right: 12px;
        margin-top: -12px;
        border: 0;
        background-color: transparent;

        span {
            @include sr-only;
        }
    }
    &__info {
        display: block;
        position: absolute;
        right: 8px;
        top: 50%;
        z-index: 2;
        transform: translateY(-50%);
    }
    &__info-text {
        position: absolute;
        z-index: 30;
        top: 28px;
        right: 8px;
        width: 240px;
        padding: 12px 28px 12px 12px;
        border: 1px solid #333;
        background-color: #fff;
        display: none;

        &.toggle-show {
            display: block;
        }
        p {
            font-size: 13px;
            color: #333;
        }
        .close {
            position: absolute;
            top: 0px;
            right: 0px;
            display: block;
            background-color: #fff;
            border: 0;
            @include size(28px);
            @include btn-close-x(11px, 1px, #333);
            span {
                @include sr-only;
            }
        }
    }
    &__key {
        height: 56px;
        line-height: 36px;
        font-size: 18px;
        color: #000;
        strong {
            color: $color-11st-red;
        }
        em {
            color: $color-11st-blue;
        }
    }
    &__tag {
        display: inline-block;
        padding: 0 7px;
        height: 20px;
        border-radius: 10px;
        background: $color-11st-red;
        font-size: 11px;
        color: $color-gray-14;
        line-height: 22px;
    }
    &__time {
        margin: 11px 16px;
        border: 0;
        background: none;
        font-size: 13px;
        color: $color-gray-04;
        line-height: 40px;

        &:after {
            @include sprite-retina($sp_eui_arrow_refresh3);
            display: inline-block;
            margin: -2px 0 1px 6px;
            vertical-align: middle;
            content: "";
        }
    }
}

// headline Properties
.c-headline--center {
    .c-headline__title {
        text-align: center;
    }
    .c-headline__subtext {
        text-align: center;
    }
}

.m-deallist {
    background: initial;
    .l-grid__row {
        background: initial;
        .l-grid__row--step1 {
            .l-grid__col--12:first-child {
                padding-top: 1px;
            }
        }
    }
}

[data-type="PageTitle"] {
    .c-headline__title {
        font-size: 18px;
    }
}
