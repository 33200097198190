@import "../../pui/sprites/sp_icons";
@mixin scroll-more($width, $height) {
    display: table;
    width: $width;
    height: $height;

    &_inner {
        display: table-cell;
        font-size: 15px;
        text-align: center;
        line-height: 22px;
        vertical-align: middle;
        color: $color-gray-03;

        &:before {
            @include sprite-retina($sp_icons_more);
            display: block;
            margin: 0 auto 20px;
            content: "";
        }
    }
}
