// X 모양의 닫기 버튼
@mixin btn-close-x($size: 10px, $weight: 1px, $color: #333, $rate: 1, $radius: false) {
    $close-height: $size * $rate;
    $close-width: $weight * $rate;
    $close-radius: $weight * $rate / 2;

    &:before,
    &:after {
        content: "";
        display: block;
        position: absolute;
        @if ($rate == 1) {
            //기존 내용 유지
            right: 50%;
        } @else {
            left: 50%;
        }
        top: 50%;
        height: $close-height;
        width: $close-width;
        @if ($rate == 1) {
            margin: -$size/2 0 0 $weight; //기존 내용 유지
        }
        @if ($radius != false) {
            border-radius: $close-radius;
        }
        background-color: $color;
    }
    @if ($rate == 1) {
        //기존 내용 유지
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    } @else {
        &:before {
            transform: translate(-50%, -50%) scale(1 / $rate) rotate(45deg);
        }
        &:after {
            transform: translate(-50%, -50%) scale(1 / $rate) rotate(-45deg);
        }
    }
}
