.c-accordion {
    background: $color-gray-14;
    margin-bottom: -1px;
    min-width: 320px;

    &__item {
        a {
            display: block;
            position: relative;
            padding: 15px 63px 13px 16px;
            border-bottom: 1px solid #f4f4f4;
            font-size: 15px;
            color: $color-gray-02;
            line-height: 19px;

            &:before {
                @include size(7px);
                @include rotate(45deg);
                @include transition(0.3s);

                position: absolute;
                top: 50%;
                right: 18px;
                margin: -4px 0 0 -7px;
                border: 1px solid $color-gray-07;
                border-width: 0 1px 1px 0;
                content: "";
            }
        }

        &.on {
            a:before {
                @include rotate(225deg);

                margin-top: -2px;
            }
        }

        &:last-child a {
            border-bottom: 0;
        }
    }

    &__detail {
        padding: 16px;
        border-bottom: 1px solid $color-gray-12;
        background: $color-gray-13;
        font-size: 14px;
        color: $color-gray-04;
        line-height: 20px;
    }
}
