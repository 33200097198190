// Only display content to screen readers. A la Bootstrap 4.
//
// See: a11yproject.com/posts/how-to-hide-content/
@mixin sr-only-reset {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    clip: auto;
    white-space: normal;
}
