.c-sticky {
    position: relative;

    &__header {
        &--sticky {
            position: sticky;
            top: 0;
        }

        &--fixed {
            position: fixed;
            top: 0;

            & + .c-sticky__dummy {
                display: block;
            }
        }

        &--absolute {
            position: absolute;
            bottom: 0;

            & + .c-sticky__dummy {
                display: block;
            }
        }
    }

    &__dummy {
        display: none;
    }
}
