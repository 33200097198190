[data-type="ProductGrid_Ranking"] {
    @include grid(2, 2, true);
    background-color: #fff !important;
    .c-card.c-card__gallery {
        .c-card__thumb {
            width: auto;
            height: auto;
        }
        .c-card__info {
            height: 156px;
            .c-card__price {
                vertical-align: middle;
            }
            .c-card__benefit {
                .benefit {
                    display: block;
                }
            }
        }
    }
    .c-card__counsel {
        padding: 14px 0 30px;
    }
    .c-ad__btn {
        position: absolute;
        left: 8px;
        bottom: 172px;
    }
    @include mq-640 {
        .c-ad__info+.c-card {
            padding-top: 30px;
        }
    }
}
