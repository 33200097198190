.c-input {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0 12px;
    background-color: $color-gray-14;
    border: 1px solid $color-gray-10;
    border-radius: 4px;
    font-size: 15px;
    color: $color-gray-02;
    line-height: 38px;
    outline: none;

    &[type="email"],
    &[type="number"],
    &[type="password"],
    &[type="search"],
    &[type="tel"],
    &[type="text"] {
        color: $color-gray-02;
    }

    &::placeholder {
        color: $color-gray-07;
    }

    &:focus {
        border-color: $color-gray-08;
        -webkit-transition: border 0.2s ease-out;
        transition: border 0.2s ease-out;
    }

    &.disabled,
    &[disabled] {
        border-color: rgba($color: #ccc, $alpha: 0.2);
        background: rgba($color: #ccc, $alpha: 0.2);
        color: $color-gray-09;

        &::placeholder {
            color: $color-gray-09;
        }
    }

    &.c-input--pass {
        &:focus {
            border-color: #5676da;
        }
    }

    &.c-input--fail {
        &:focus {
            border-color: #f44;
        }
    }

    &.c-input__line {
        border: 0;
        background: transparent;
        border-bottom: 1px solid $color-gray-10;

        &:focus {
            border-color: $color-gray-08;
        }

        &.c-input--pass {
            &:focus {
                border-color: #5676da;
            }
        }

        &.c-input--fail {
            &:focus {
                border-color: #f44;
            }
        }
    }
}
