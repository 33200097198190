[data-type="ContentsGrid_ImgText_Col2"] {
    @include grid(1, 1, false);
    .c-card__thumb {
        &:before {
            @include size(100%);
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            border: 1px solid #d8d8d8;
            z-index: 1;
        }
        img {
            width: 100%;
            height: auto;
            max-width: initial;
            max-height: initial;
            left: -100%;
            right: -100%;
        }
    }
    .c-card__info {
        padding: 8px 8px 8px 0;
        .c-card__name {
            min-height: 42px;
            margin-bottom: 0;
            -webkit-line-clamp: initial;
        }
    }
}
