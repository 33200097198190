[data-type="Tabs_Text_Col2"] {
    .c-tab {
        padding-top: 0;
        border-bottom: 1px solid #f4f4f4;
        .c-tab__list {
            .c-tab__link {
                display: block;
                position: relative;
                height: initial;
                margin-right: 0;
                padding: 2px 8px;
                line-height: 39px;
                color: #999;
                background: #fafafa;
                border-right: 1px solid #f4f4f4;
            }
            &:last-child a {
                border-right: 0;
                border-color: $color-gray-12;
            }
            .c-tab__item {
                &.on {
                    .c-tab__link {
                        color: #333;
                        background: #fff;
                        border: 0;
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
