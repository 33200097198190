@import "../sprites/sp_sorting";

.c-sorting {
    position: relative;
    min-width: 320px;
    height: 44px;
    background: $color-gray-14;
    letter-spacing: -1px;
    border-bottom: 1px solid $color-gray-12;

    &__qty {
        float: left;
        position: relative;
        overflow: hidden;
        display: block;
        padding-left: 10px;
        padding-right: 30px;
        height: 44px;
        color: $color-gray-03;
        font-size: 13px;
        line-height: 44px;

        strong {
            color: $color-gray-02;
        }

        &:after {
            height: 0;
            width: 0;
            content: "";
            display: block;
            position: absolute;
            top: 42%;
            right: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 6px solid #bbb;
            z-index: 1;
        }
    }

    span.c-sorting__qty {
        padding-right: 10px;

        &:after {
            display: none;
        }
    }

    &__view {
        @include size(44px 44px);
        overflow: hidden;
        display: block;
        float: right;
        border-left: 1px solid $color-gray-12;

        a {
            @include size(44px 44px);
            display: block;
            position: relative;
            text-indent: -99999px;
        }

        &__list:before {
            content: "";
            display: block;
            position: absolute;
            top: 10px;
            left: 10px;
            @include sprite-retina($sp-sorting-view-list);
        }

        &__gallery:before {
            content: "";
            display: block;
            position: absolute;
            top: 10px;
            left: 10px;
            @include sprite-retina($sp_sorting_view_gallery);
        }

        &__big:before {
            content: "";
            display: block;
            position: absolute;
            top: 10px;
            left: 10px;
            @include sprite-retina($sp_sorting_view_big);
        }
    }

    &__order {
        height: 44px;
        line-height: 44px;
        float: right;
        border-left: 1px solid $color-gray-12;

        &__button {
            display: block;
            position: relative;
            padding-right: 10px;
            padding-left: 30px;
            height: 44px;
            line-height: 44px;
            color: $color-gray-03;
            font-size: 13px;

            &:before {
                content: "";
                position: absolute;
                left: 5px;
                top: 10px;
                display: inline-block;
                @include sprite-retina($sp_sorting_arrow);
            }
        }
    }

    &__filter {
        position: relative;
        float: right;
        border-left: 1px solid $color-gray-12;

        &-button {
            display: block;
            height: 44px;
            line-height: 44px;
            font-size: 13px;
            color: #333;
            padding: 0 8px 0 36px;

            &:before {
                content: "";
                position: absolute;
                left: 8px;
                top: 10px;
                @include sprite-retina($sp-sorting-filter);
            }
        }
    }

    &__subline {
        display: block;
        clear: both;
        border-top: 1px solid #f4f4f4;

        .c-sorting__order {
            float: left;
        }
    }

    &__filter-checkbutton {
        height: 44px;
        line-height: 44px;
        float: right;
        padding: 0 16px 0 10px;
        border-left: 1px solid $color-gray-12;

        .c-checkbox-toggle {
            em {
                margin-top: 10px;
                float: right;
            }

            span {
                padding-left: 0;
                padding-right: 8px;
                height: 44px;
                line-height: 44px;
                font-size: 13px;
            }
        }
    }
}
