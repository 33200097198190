.skpui-calendar {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    padding-top: 40px;
    z-index: 202;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;

    .reserve_pos_contents {
        height: 100%;
        background-color: #e3e3e8;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    .close {
        position: absolute;
        top: 0;
        right: 0;
        width: 39px;
        height: 39px;
        border: none;
        background: none;
        color: transparent;
        font-size: 10px;

        &:before, &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 1px;
            height: 18px;
            margin-top: -9px;
            background: #333;
            content: '';
        }

        &:before {
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        &:after {
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }
    }

    p {
        margin: 15px 15px 0;
        color: #333;
        font-size: 14px;
        line-height: 18px;

        em {
            color: #ec2910;
        }
    }

    .s_completion {
        position: relative;
        bottom: 47px;
        left: 0;
        width: 100%;
        height: 47px;
        color: #fff;
        border: 0;
        background-color: #8584f3;
        font-size: 16px;
    }

    .calendar {
        overflow: hidden;
        overflow-y: scroll;
        height: 100%;
        padding-bottom: 57px;
        margin-top: -1px;
        padding-top: 1px;
        background-color: #fff;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-overflow-scrolling: touch;
    }

    .area_month {
        position: relative;
        height: 56px;

        b {
            display: block;
            text-align: center;
            height: 56px;
            line-height: 56px;
            color: #2f2f2f;
            letter-spacing: 0;
        }

        button {
            position: absolute;
            top: 10px;
            content: '';
            display: block;
            width: 36px;
            height: 36px;
            border: 1px solid #e3e3e8;
            -webkit-border-radius: 18px;
            background: none;
            color: transparent;
            text-indent: -9999px;
            -webkit-tap-highlight-color: inherit;

            &:before {
                position: absolute;
                top: 50%;
                left: 50%;
                content: '';
                display: block;
                width: 7px;
                height: 7px;
                margin: -4px 0 0 -3px;
                border-top: 1px solid #666;
                border-right: 1px solid #666;
                -webkit-transform: rotate(-135deg);
                transform: rotate(-135deg);
            }
        }

        .view_prev {
            left: 15px;
        }

        .view_next {
            right: 15px;
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
        }
    }

    .area_day {
        margin-top: 10px;
        -webkit-tap-highlight-color: inherit;

        table {
            width: 100%;
        }

        th, td {
            width: 52px;
            text-align: center;
            vertical-align: top;
            font-size: 12px;
        }

        th {
            position: relative;
            vertical-align: middle;
            height: 46px;

            &:before, &:after {
                position: absolute;
                left: 0;
                bottom: 0;
                content: '';
                display: block;
                width: 100%;
                height: 1px;
            }

            &:before {
                background-color: #f4f4f4;
            }

            &:after {
                border-bottom: 1px dotted #e3e3e8;
            }
        }

        td {
            position: relative;
            border-bottom: 1px solid #f2f2f2;

            button {
                position: relative;
                width: 100%;
                padding: 5px 0;
                background: none;
                border: 0;
                min-width: 46px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                -webkit-transition: .8s;
                transition: .8s;
                outline: none;
            }

            span {
                display: block;

                &:only-child:after {
                    display: block;
                    content: '';
                    width: 100%;
                    height: 26px;
                    margin-top: 8px;
                    clear: both;
                }
            }

            em {
                display: block;
                height: 26px;
                margin-top: 8px;
            }
        }

        .day_sunday {
            button {
                color: #e92009;
            }
        }

        .day_saturday {
            button {
                color: #1f79cf;
            }
        }

        .day_holiday {
            button {
                color: #e92009;
            }
        }

        .day_otherMonth {
            button {
                color: #ccc;
                display: none;
            }
        }

        .day_impossible {
            button {
                color: #ccc;

                &:before, &:after {
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 20%;
                    display: block;
                    width: 1px;
                    height: 12px;
                    margin-top: -6px;
                    background-color: #c3c3c3;
                    -webkit-transform: rotate(45deg) translateX(-50%);
                    transform: rotate(45deg) translateX(-50%);
                }

                &:after {
                    -webkit-transform: rotate(135deg);
                    transform: rotate(135deg);
                }
            }
        }

        %today {
            background-color: $color-11st-red;
            color: #fff;
            -webkit-border-radius: 2px;
            border-radius: 2px;
        }

        .day_today {
            button {
                @extend %today;

                span {
                    color: #dfdfd4;
                }
            }
        }

        .day_selected {
            button {
                @extend %today;

                span {
                    color: #fff;
                }
            }
        }
    }
}
