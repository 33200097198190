// 슈팅 common : 슈팅배송, 슈팅설치, 슈팅배송+2일, 슈팅신선
@import "../../svg/sp_common";

@mixin retail-common($icon, $width) {
    font-size: 14px;

    strong {
        @include get-sprite-common($icon, $width);
        overflow: hidden;
        color: transparent;
        vertical-align: middle;
    }

    em,
    .c-card-item__point {
        color: $color-11st-blue;
    }

    @content;
}
