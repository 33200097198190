$sprite-meta-live11: (
    "name": 'live11', // sprite 이름
    "width": 134, // sprite 판 너비
    "height": 124, // sprite 판 높이
);

$sprite-map-live11: (
  'btn-play': (
      "width": 60,
      "height": 60,
      "bg-top": 0 - (68 - 60)/2,
      "bg-left": 0 - (68 - 60)/2,
  ),
  'ic-alarm': (
      "width": 20,
      "height": 20,
      "bg-top": -38 - (28 - 20)/2,
      "bg-left": -68 - (28 - 20)/2,
  ),
  'ic-alarm-off': (
      "width": 20,
      "height": 20,
      "bg-top": 0 - (28 - 20)/2,
      "bg-left": -106 - (28 - 20)/2,
  ),
  'ic-chat': (
      "width": 24,
      "height": 24,
      "bg-top": -68 - (32 - 24)/2,
      "bg-left": 0 - (32 - 24)/2,
  ),
  'ic-chevron-right': (
      "width": 8,
      "height": 14,
      "bg-top": -78 - (22 - 14)/2,
      "bg-left": -106 - (16 - 8)/2,
  ),
  'ic-delete': (
      "width": 24,
      "height": 24,
      "bg-top": -68 - (32 - 24)/2,
      "bg-left": -32 - (32 - 24)/2,
  ),
  'ic-playvod': (
      "width": 12,
      "height": 12,
      "bg-top": -100 - (20 - 12)/2,
      "bg-left": -72 - (20 - 12)/2,
  ),
  'ic-plus': (
      "width": 16,
      "height": 16,
      "bg-top": -54 - (24 - 16)/2,
      "bg-left": -106 - (24 - 16)/2,
  ),
  'ic-reloading': (
      "width": 18,
      "height": 18,
      "bg-top": -28 - (26 - 18)/2,
      "bg-left": -106 - (26 - 18)/2,
  ),
  'ic-schedule': (
      "width": 16,
      "height": 16,
      "bg-top": -100 - (24 - 16)/2,
      "bg-left": 0 - (24 - 16)/2,
  ),
  'ic-search': (
      "width": 30,
      "height": 30,
      "bg-top": 0 - (38 - 30)/2,
      "bg-left": -68 - (38 - 30)/2,
  ),
  'ic-setting': (
      "width": 16,
      "height": 16,
      "bg-top": -100 - (24 - 16)/2,
      "bg-left": -24 - (24 - 16)/2,
  ),
  'ic-share': (
      "width": 24,
      "height": 24,
      "bg-top": -68 - (32 - 24)/2,
      "bg-left": -64 - (32 - 24)/2,
  ),
  'icon_play': (
      "width": 16,
      "height": 16,
      "bg-top": -100 - (24 - 16)/2,
      "bg-left": -48 - (24 - 16)/2,
  ),
);

// [STEP2] (선택) 기본 선언 후 이미지 크기와 위치를 오버라잇 없이 각각 설정
@mixin get-sprite-position-live11($name, $width: null, $type: null) {
    $sp-w: map-get($sprite-meta-live11, "width");
    $sp-h: map-get($sprite-meta-live11, "height");
    $img: map-get($sprite-map-live11, $name);
    $img-w: map-get($img, "width");
    $img-h: map-get($img, "height");
    $img-r: $img-w / $img-h;
    $img-bgt: map-get($img, "bg-top");
    $img-bgl: map-get($img, "bg-left");

    @if $width == null {
      $width: $img-w;
    }

    $height: $width / $img-r;

    $bgw: floor($width * ($sp-w / $img-w));
    $bgh: floor($height * ($sp-h / $img-h));
    $bgl: ($bgw / $sp-w) * $img-bgl;
    $bgt: ($bgh / $sp-h) * $img-bgt;

    width: #{$width}px;
    height: #{$height}px;

    @if $type == null {
      background-position: #{$bgl}#{"px"} #{$bgt}#{"px"};
      background-size: #{$bgw}px #{$bgh}px;
    } @else if $type == "mask" {
      mask-position: #{$bgl}#{"px"} #{$bgt}#{"px"};
      mask-size: #{$bgw}px #{$bgh}px;
    }
}

// [STEP1] (필수) 기본 선언
@mixin get-sprite-live11($name: null, $tmp-width: null, $type: null) {
    display: inline-block;
    
    @if $type == null {
      vertical-align: top;
      background: url("/MW/img/svg/sprites/sp_live11_2024215_192803.svg") no-repeat;
    } @else if $type == "mask" {
      mask-image: url("/MW/img/svg/sprites/sp_live11_2024215_192803.svg");
    }
    @if $name != null {
      @include get-sprite-position-live11($name, $width: $tmp-width, $type: $type);
    }
}