@import "../../main/sprites/sp_flags";
@import "../sprites/sp_review";
@import "../../common/partial/definition";
@import "../../common/partial/price";
@import "../../pui/sprites/sp_icons";
@import "../../pui/sprites/sp_star"; //노란색 별점 이미지
@import "../../common/sprites/sp_soldout";
@import "../../svg/_sp_common";

.c-card {
    background: $color-gray-14;
    display: block;
    position: relative;
    overflow: hidden;

    &__thumb {
        display: block;
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: initial;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
            margin: auto;
        }

        & .c-card__thumb-emblem {
            z-index: 1;
            left: 6px;
            top: 6px;
            width: 34px;
            height: 34px;
            margin: 0;
        }
    }

    &__info {
        display: block;
        position: relative;
        padding: 8px 16px;
        background: $color-gray-14;

        .c-card__name {
            color: $color-gray-01;
            display: -webkit-box;
            height: 19px;
            max-height: 4.2em;
            overflow: hidden;
            margin-bottom: 10px;
            font-size: 15px;
            line-height: 1.4em;
            word-break: break-all;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .c-card__brand {
            display: block;
            font-size: 13px;
            color: #999;
        }

        .c-card__rate {
            float: left;
            display: block;
            padding-right: 4px;
            font-size: 12px;
            line-height: 18px;
            color: $color-11st-red;

            strong {
                display: inline-block;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                vertical-align: bottom;

                &.c-card__special {
                    font-size: 13px;
                }
            }
        }

        .c-card__price {
            color: $color-gray-01;
            display: inline-block;
            position: relative;
            font-size: 12px;
            line-height: 18px;
            white-space: nowrap;
            vertical-align: top;

            strong {
                display: inline-block;
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                // vertical-align: bottom;
            }

            del {
                color: $color-gray-07;
                display: inline-block;
                padding-left: 4px;
                font-size: 12px;
                line-height: 16px;
                white-space: nowrap;
            }

            div {
                line-height: 16px;
            }
        }

        .c-card__benefit {
            color: $color-gray-04;
            display: block;
            box-sizing: border-box;
            padding-top: 15px;
            font-size: 12px;
            letter-spacing: -0.5px;

            strong {
                color: #ff8411;
                padding-right: 6px;
                font-weight: normal;
            }

            &-secondary {
                display: block;
                color: #8278ff;
            }

            .gap + .gap {
                margin-left: 6px;
            }

            .benefit {
                display: block;
                &:first-child {
                    padding-bottom: 2px;
                }
                em {
                    color: $color-11st-blue;
                }
            }
        }

        .c-card__fee {
            position: absolute;
            bottom: 17px;
            right: 8px;
            font-size: 12px;
            color: #b3b3b3;
        }

        .c-card__qty {
            position: absolute;
            bottom: 9px;
            right: 16px;
            font-size: 13px;
            color: $color-gray-04;

            em {
                color: $color-11st-blue;
            }

            &--urgent {
                animation: 3s linear 0s urgent infinite;

                & + .c-card__qty--urgent {
                    animation-delay: -1.5s;
                }
            }
        }

        .c-card__grade {
            // sass/common/partial/_starrate.scss 로 대체 시 삭제 예정
            overflow: hidden;
            margin-top: 7px;

            .c-card__sati {
                @include logo-svg($logo-icon_star, 64px, 12px);
                position: relative;
                float: left;
                overflow: hidden;
                margin-right: 5px;
                color: transparent;
                font-size: 10px;

                &:after {
                    @include logo-svg($logo-icon_star_fill, 64px, 12px);
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                &.mj100:after {
                    width: 100%;
                }

                &.mj90:after {
                    width: 90%;
                }

                &.mj80:after {
                    width: 80%;
                }

                &.mj70:after {
                    width: 70%;
                }

                &.mj60:after {
                    width: 60%;
                }

                &.mj50:after {
                    width: 49%;
                }

                &.mj40:after {
                    width: 40%;
                }

                &.mj30:after {
                    width: 30%;
                }

                &.mj20:after {
                    width: 20%;
                }

                &.mj10:after {
                    width: 10%;
                }
            }

            .c-card__rev {
                color: $color-gray-04;
                float: left;
                font-size: 12px;
                letter-spacing: $body-spacing;
                line-height: 15px;
            }
        }

        .c-card__flag {
            position: relative;
            display: block;
            padding-bottom: 3px;
            font-size: 0;
            white-space: nowrap;

            &-icon {
                @include size(16px);
                display: inline-block;
                text-indent: -9999px;
            }

            &-text {
                display: inline-block;
                margin-left: 3px;
                padding-left: 1px;
                color: #9c9c9c;
                font-size: 13px;
                line-height: 17px;
                vertical-align: top;

                &:last-child {
                    color: #666;
                }
            }
        }

        .c-card__image {
            @include size(43px 16px);
            display: block;

            img {
                @include size(43px 16px);
                display: block;
            }
        }

        .c-card__text {
            font-size: 13px;
            color: #968e86;
        }

        .c-card__charge {
            color: $color-gray-07;
            display: block;
            box-sizing: border-box;
            padding-top: 10px;
            height: 24px;
            font-size: 12px;

            strong {
                padding-right: 5px;
                font-weight: normal;
            }
        }

        .flags {
            &_www {
                .c-card__flag-icon {
                    @include sprite-retina($sp_flags_www_s);
                }
            }

            &_usa {
                .c-card__flag-icon {
                    @include sprite-retina($sp_flags_usa_s);
                }
            }

            &_fra {
                .c-card__flag-icon {
                    @include sprite-retina($sp_flags_fra_s);
                }
            }

            &_ity {
                .c-card__flag-icon {
                    @include sprite-retina($sp_flags_ity_s);
                }
            }

            &_eng {
                .c-card__flag-icon {
                    @include sprite-retina($sp_flags_eng_s);
                }
            }

            &_ger {
                .c-card__flag-icon {
                    @include sprite-retina($sp_flags_ger_s);
                }
            }

            &_jpn {
                .c-card__flag-icon {
                    @include sprite-retina($sp_flags_jpn_s);
                }
            }

            &_spn {
                .c-card__flag-icon {
                    @include sprite-retina($sp_flags_spn_s);
                }
            }

            &_twi {
                .c-card__flag-icon {
                    @include sprite-retina($sp_flags_twi_s);
                }
            }

            &_hk {
                .c-card__flag-icon {
                    @include sprite-retina($sp_flags_hk_s);
                }
            }

            &_can {
                .c-card__flag-icon {
                    @include sprite-retina($sp_flags_can_s);
                }
            }

            &_chn {
                .c-card__flag-icon {
                    @include sprite-retina($sp_flags_chn_s);
                }
            }

            &_etc {
                .c-card__flag-icon {
                    @include sprite-retina($sp_flags_etc_s);
                }
            }
        }

        .c-card__mart {
            display: block;
            font-size: 13px;
            color: #7baed8;
            line-height: 20px;
            height: 18px;
            margin-bottom: 5px;
            letter-spacing: 0;

            span:nth-child(2) {
                margin-left: 4px;
            }

            &.c-card__mart-emart {
                color: $color-emart;
            }

            &.c-card__mart-hp {
                color: $color-homeplus;
            }

            &.c-card__mart-gsfresh {
                color: $color-gsfresh;
            }

            &.c-card__mart-gsdawn {
                color: $color-gsdawn;
            }

            &.c-card__mart-ssg {
                color: $color-ssg;
            }

            &--rank {
                display: inline-block;
                font-size: 9px;
                width: 18px;
                height: 18px;
                line-height: 18px;
                margin-right: 6px;
                color: #fff;
                text-align: center;
                background-color: #f75b68;
                position: relative;
                top: -2px;
                letter-spacing: 0;
            }
        }
    }

    &__review-badge {
        position: absolute;
        left: 6px;
        top: 6px;
        padding: 0 6px;
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0.3);
        font-size: 10px;
        line-height: 14px;
        color: #fff;
    }

    &.c-card__deal {
        .c-card__thumb {
            .c-badge-shocking {
                overflow: hidden;
                position: absolute;
                top: 8px;
                left: 8px;
                width: 80px;
                height: 80px;
                color: transparent;
                z-index: 1;

                img {
                    @include size(80px);
                }
            }

            img {
                display: block;
                position: relative;
            }
        }

        .c-card__name {
            -webkit-line-clamp: 1;
        }

        .c-card__rate {
            vertical-align: bottom;

            strong {
                vertical-align: bottom;
            }
        }

        .c-card__price {
            vertical-align: bottom;
            line-height: 20px;

            del {
                font-size: $font-size-body2;
                color: $color-gray-07;
            }
        }

        .c-card__mov__button {
            top: 50%;
            height: auto;
            margin-top: -52px;
        }
    }

    &.c-card__gallery {
        padding: 0;

        .c-card__thumb {
            @include size(136px);
            margin: 0 auto;

            @media screen and (min-width: 360px) and (max-width: 374px) {
                & {
                    @include size(156px);
                }
            }

            @media screen and (min-width: 375px) and (max-width: 413px) {
                & {
                    @include size(163px);
                }
            }

            @media screen and (min-width: 414px) and (max-width: 459px) {
                & {
                    @include size(183px);
                }
            }

            @media screen and (min-width: 460px) and (max-width: 479px) {
                & {
                    @include size(206px);
                }
            }

            @media screen and (min-width: 480px) and (max-width: 639px) {
                & {
                    @include size(216px);
                }
            }
        }

        .c-card__info {
            padding: 13px 0 0;

            .c-card__name {
                -webkit-line-clamp: 2;
                height: 40px;
            }

            .c-card__price {
                height: 36px;
                line-height: initial;

                del {
                    display: block;
                    padding-left: 0;
                }
            }

            .c-card__rate {
                strong {
                    font-weight: bold;
                }
            }

            .c-card__benefit {
                padding-top: 6px;
            }
        }
    }

    &.c-card__list {
        min-height: 144px;

        .c-card__thumb {
            @include size(144px);
            position: absolute;

            &.play {
                &:after {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    @include sprite-retina($sp_review_play_s);
                    content: "";
                }
            }
        }

        .c-card__info {
            padding: 0 8px 6px 8px;
            margin-left: 144px;

            .c-card__name {
                -webkit-line-clamp: 2;
                height: 40px;
            }

            .c-card__price {
                del {
                    display: block;
                    padding-left: 0;
                }
            }

            .c-card__benefit {
                padding-top: 10px;
            }
        }
    }

    &.c-card__big {
        .c-card__thumb {
            @include size(288px);
            padding: 0;
            margin: 0 auto;

            @media screen and (min-width: 360px) {
                & {
                    @include size(328px);
                }
            }
        }

        .c-card__info {
            .c-card__name {
                height: initial;
                -webkit-line-clamp: 3;
            }

            @media screen and (min-width: 640px) {
                & {
                    min-height: 110px;
                }
            }

            .c-card__benefit {
                padding-top: 8px;

                .benefit {
                    display: inline-block;
                    margin-right: 4px;
                    padding: 1px 5px;
                    border: 1px solid #eee;
                }
            }
        }
    }

    &.c-card__onlyprice {
        position: relative;
        border: 1px solid rgba(0, 0, 0, 0.05);

        .c-card__thumb {
            img {
                height: 100%;
                max-height: initial;
            }

            &:after {
                content: "";
                display: block;
                padding-top: 100%;
            }
        }

        .c-card__info {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            background: transparent;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 110px;
                background: linear-gradient(180deg, transparent 50%, rgba(0, 0, 0, 0.28) 80%, rgba(0, 0, 0, 0.39));
            }

            .c-card__price {
                color: $color-gray-14;
                font-size: 15px;

                strong {
                    font-size: 15px;
                }
            }
        }
    }

    &.c-card__slide-big {
        border: 1px solid $color-gray-12;
        width: 240px; // margin-bottom: 1px;

        .c-card__thumb {
            @include size(240px);
        }
    }

    &.c-card__slide-deal {
        @extend .c-card__slide-big;

        .c-card__thumb {
            @include size(300px 150px);
        }

        width: 300px;
    }

    &.c-card__slide-gallery {
        @extend .c-card__slide-big;
        width: 144px;

        .c-card__thumb {
            @include size(144px);
        }

        .c-card__info {
            padding: 8px;

            .c-card__name {
                height: 40px;
            }

            .c-card__price {
                del {
                    display: block;
                    padding-left: 0;
                }
            }
        }
    }

    &.c-card__slide-w104 {
        @extend .c-card__slide-big;
        width: 104px;

        .c-card__thumb {
            @include size(104px);
        }

        .c-card__info {
            padding: 8px;

            .c-card__name {
                height: 40px;
            }
        }
    }

    &.c-card__slide-w100 {
        @extend .c-card__slide-big;
        width: 100px;

        .c-card__thumb {
            @include size(100px);
        }

        .c-card__info {
            padding: 8px;

            .c-card__name {
                margin-bottom: 0;
                height: 40px;
            }
        }
    }

    &.c-card__slide-w96 {
        @extend .c-card__slide-big;
        width: 96px;

        .c-card__thumb {
            @include size(96px);
        }

        .c-card__info {
            display: none;
        }
    }

    &.c-card__slide-w82 {
        @extend .c-card__slide-big;
        width: 82px;

        .c-card__thumb {
            @include size(82px);
        }

        .c-card__info {
            padding: 8px;

            .c-card__name {
                margin-bottom: 0;
                height: 40px;
            }
        }
    }

    &.c-card__slide-w76 {
        @extend .c-card__slide-big;
        width: 76px;

        .c-card__thumb {
            @include size(76px);
        }

        .c-card__info {
            padding: 8px;

            .c-card__name {
                margin-bottom: 0;
                height: 40px;
            }
        }
    }

    &.c-card--soldout {
        .c-card__soldout__text {
            @include size(100%);
            position: absolute;
            top: 0;
            left: 0;
            color: transparent;
            background: rgba(17, 17, 17, 0.45);
            z-index: 2;

            &:before {
                position: absolute;
                top: 46%;
                left: 0;
                width: 100%;
                margin-top: -20px;
                color: $color-gray-14;
                font-weight: bold;
                font-size: 26px;
                line-height: 40px;
                text-align: center;
                letter-spacing: 1px;
                z-index: 3;
                content: "SOLD OUT";
            }
        }

        .c-card__timedeal-text {
            display: block;
            position: absolute;
            top: 54%;
            width: 100%;
            text-align: center;
            color: #fff;
            font-size: 13px;
            text-decoration: underline;
            z-index: 2;
        }

        .c-card__info {
            min-height: 65px;
        }

        &.c-card__list {
            .c-card__soldout__text:before {
                font-size: 20px;
            }
        }
    }

    &.c-card--keyword {
        @include size(100%);
        border: 1px solid #f4f4f4;

        .c-card__thumb {
            @include size(100%);
            margin: 0 auto;
            min-width: 90px;
            min-height: 90px;

            img {
                position: initial;
                left: initial;
                right: initial;
                top: initial;
                bottom: initial;
                margin: initial;
                width: 100%;
                max-width: 100%;
                max-height: 100%;
            }
        }

        .c-card__info {
            .c-card__name {
                height: 16px;
                font-size: 13px;
                text-align: center;
                margin-bottom: 0;
            }
        }
    }

    &.c-card__slide-keyword {
        @extend .c-card__slide-big;
        width: 100px;

        .c-card__thumb {
            @include size(100px);
        }

        .c-card__info {
            padding: 8px;

            .c-card__name {
                height: 16px;
                font-size: 12px;
                text-align: center;
                margin-bottom: 0;
            }
        }

        &.c-card__slide-keyword-small {
            width: 82px;
            padding: 8px 0 5px; // margin-bottom: 0;

            .c-card__thumb {
                @include size(80px 48px);
            }

            .c-card__info {
                margin-top: 3px;
                padding: 0;

                .c-card__name {
                    line-height: 16px;
                }
            }
        }
    }

    &.c-card--borderless {
        border: 0;

        .c-card__thumb {
            background: #f4f4f4;
            border-radius: 32px;

            img {
                @include size(64px);
            }
        }

        .c-card__info {
            .c-card__name {
                font-size: 12px;
                text-align: center;
            }
        }
    }

    &.c-card--brand {
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.4);
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        .c-card__brand-info {
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;

            .c-card__brand-logo {
                display: block;
                margin: 30% auto auto auto;
                border-radius: 52px;
                background: #fff;
                @include size(50px);

                @include mq-320 {
                    & {
                        @include size(50px);
                    }
                }

                @include mq-360 {
                    & {
                        @include size(64px);
                    }
                }

                @include mq-640 {
                    & {
                        @include size(50px);
                    }
                }
            }

            img {
                @include size(100%);
                display: block;
                border-radius: 52px;
            }

            .c-card__brand-name {
                padding: 4px 8px;
                background: transparent;
                font-size: 12px;
                color: #fff;
                text-align: center;
            }
        }
    }

    .c-card-badge {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 0;
        z-index: 2;

        &__status {
            display: inline-block;
            padding: 3px 8px;
            background-color: #666;
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;
            color: #fff;
            letter-spacing: 0;
            vertical-align: top;

            &--active {
                background-color: $color-11st-red;
            }
        }

        &__time {
            display: inline-block;
            padding: 3px 8px;
            background-color: #fafafa;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #333;
            letter-spacing: 1px;
            vertical-align: top;
        }
    }

    .c-card__badge-text {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
    }

    &--box {
        @include rounding-box;
    }

    &.c-card__dealcard {
        .c-card__thumb {
            .c-badge-shocking {
                overflow: hidden;
                position: absolute;
                top: 8px;
                left: 8px;
                width: 80px;
                height: 80px;
                color: transparent;
                z-index: 1;

                img {
                    @include size(80px);
                }
            }
            .c-ad__btn {
                position: absolute;
                bottom: -1px;
            }
            .c-badge-date {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                height: 24px;
                line-height: 26px;
                padding: 0 8px;
                background-color: rgba($color: $color-11st-red, $alpha: 0.8);
                color: #fff;
                text-align: center;
                font-size: 11px;
                border-bottom-right-radius: 4px 4px;
                font-weight: bold;
            }
        }

        .c-card__name {
            height: 21px;
            line-height: 19px;
            padding-top: 3px;
            margin-bottom: 3px;
            color: #333;
            &-addition {
                display: block;
                padding-bottom: 10px;
                color: $color-11st-blue;
                font-size: $font-size-body1;
                word-break: break-all;
            }
        }

        .c-card__rate {
            display: inline-block;
            line-height: 25px;
            padding-right: 4px;
            font-size: 19px;
            font-weight: bold;
            vertical-align: bottom;
            color: $color-11st-red;

            strong {
                font-size: 21px;
                color: $color-11st-red;
                line-height: 26px;
                vertical-align: bottom;
            }
            em {
                font-size: 16px;
                vertical-align: bottom;
                font-weight: normal;
            }
        }

        .c-card__price {
            line-height: 21px;
            vertical-align: bottom;

            strong {
                font-size: 22px;
                color: #111;
                line-height: 25px;
                vertical-align: bottom;
            }

            del {
                font-size: 13px;
                color: #bbb;
                padding-left: 5px;
            }
        }

        .c-card__soldout-text {
            font-size: 13px;
            margin-top: 5px;
            color: #999;
            padding-bottom: 8px;
        }

        .c-card__time-text {
            @include size(100%);
            display: flex;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            background-color: rgba(0, 0, 0, 0.5);
            font-size: 24px;
            color: $color-gray-14;
            text-align: center;
            justify-content: center;
            align-items: center;

            time {
                display: block;
                font-size: 38px;
                line-height: 1;
            }
        }

        .c-card__subname {
            font-size: 13px;
            margin-top: 0;
            color: #666;
            padding-bottom: 0;
        }

        .c-card__benefit {
            padding: 8px 0 0;
            overflow: hidden;

            &::after {
                content: "";
                display: table;
                clear: both;
            }

            .benefit {
                display: inline-block;
                position: relative;
                border: 0 none;
                font-size: $font-size-body2;
                color: $color-gray-07;
                margin: 0 12px 0 -12px;
                padding: 0 0 0 12px;

                &::before {
                    content: "";
                    display: block;
                    width: 2px;
                    height: 2px;
                    border-radius: 1px;
                    background-color: #ddd;
                    position: absolute;
                    left: 4px;
                    top: 50%;
                    margin-top: -2px;
                }
            }
        }

        & > .c-card__info {
            padding: 0px;
        }

        .c-card--relates {
            padding: 0px 16px 8px;

            .c-addition {
                padding: 0 1px;
                background-color: #fff;
                font-size: 13px;
                color: #333;
                line-height: 19px;
                border-top: 1px solid rgba(0, 0, 0, 0.04);

                > div,
                > ul,
                > dl,
                > a {
                    display: flex;
                    margin-top: 6px;
                    padding: 8px 0 6px;
                }

                &-coupon__rate {
                    font-size: 11px;
                }
            }
        }

        @at-root .c-card__delivery {
            position: relative;
            height: 38px;
            padding: 0 16px;
            line-height: 38px;
            font-size: 13px;
            border-top: 1px solid $color-gray-12;
            color: $color-gray-04;

            .c-card__free {
                position: absolute;
                left: 16px;
            }

            .c-card__today {
                display: inline-block;
                max-width: 100%;
                overflow: hidden;
                position: relative;
                height: inherit;

                span {
                    max-width: 100%;
                    display: inline-block;
                    position: relative;
                    @include text-ellipsis;
                }

                em {
                    margin-right: 6px;
                    color: $color-11st-blue;
                }

                &:nth-child(2) {
                    padding: 0 120px 0 6px;
                    margin-left: 48px;
                }

                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    margin-top: -1px;
                    @include separators-dot(2px, 2px, #dddddd);
                }

                &:first-child {
                    &:before {
                        display: none;
                    }
                }
            }

            .c-card__qty {
                bottom: 0;
                color: $color-gray-04;
                line-height: 36px;
                em {
                    color: $color-11st-blue;
                }
            }

            .c-card__soldout {
                color: $color-11st-red;
            }
        }

        .c-card__link {
            display: block;

            .c-card__info {
                padding: 12px 16px 10px 16px;
            }
        }

        .c-card__soldout__text {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            color: #fff;
            background-color: rgba(0, 0, 0, 0.3);
            z-index: 2;
            line-height: 100%;

            span {
                position: absolute;
                top: 50%;
                font-size: 32px;
                text-align: center;
                width: 100%;
                line-height: 1;
                height: 32px;
                font-weight: bold;
                margin-top: -16px;
            }
        }

        .c-card-item__soldout-text {
            @include size(100%);
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            background: rgba(0, 0, 0, 0.3);
            color: transparent;

            &::before {
                @include sprite-retina($sp_soldout_soldout);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                content: "";
            }
        }

        .c-addition-link--single {
            &:after {
                @include rotate(45deg);
                @include size(9px);
                content: "";
                position: absolute;
                top: 50%;
                right: 1px;
                margin-top: -3px;
                border: solid #666;
                border-width: 1px 1px 0 0;
            }
        }

        .c-ad__btn {
            position: relative;
            bottom: 111px;
        }

        .c-card__delivery-info {
            @include retail-delivery;
            @include retail-install;
            @include retail-fresh;
            @include retail-plus;
            @include retail-seller;

            display: flex;
            position: relative;
            padding: 0 16px;
            border-top: 1px solid $color-gray-12;

            dt {
                @include sr-only;
            }
            dd {
                & + dd {
                    &:before {
                        @include size(2px);
                        display: inline-block;
                        margin: -1px 5px 1px;
                        border-radius: 2px;
                        background: $color-gray-10;
                        vertical-align: middle;
                        content: "";
                    }
                }
            }
            .c-card__delivery {
                display: flex;
                flex: 1;
                overflow: hidden;
                padding: 0;
                border-top: 0;
                white-space: normal;
            }
            .delivery {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &:first-of-type {
                    overflow: initial;
                    text-overflow: initial;
                }
            }
            .sktpass {
                font-size: 13px;
                color: $color-gray-04;
                white-space: nowrap;

                &:before {
                    @include get-sprite-common("logo_sktpass", 56);
                    margin: -1px 0 1px;
                    vertical-align: middle;
                    content: "";
                }
                em,
                .c-card-item__point {
                    font-weight: normal;
                    color: $color-11st-blue;
                }
            }
            .retail-delivery {
                strong {
                    &:before {
                        @include size(20px);
                        background-size: 20px 20px;
                    }
                }
            }
            .c-card__soldout {
                color: $color-11st-red;
            }
            .c-card__qty {
                bottom: 50%;
                transform: translateY(50%);
            }
        }
        .c-card-item {
            &__lowest-info {
                margin-top: 6px;
            }
        }
    }

    &__counsel {
        display: block;
        padding: 14px 0;
        font-size: 12px;
        line-height: 17px;
        color: $color-gray-04;

        em {
            display: block;
            color: $color-11st-blue;
        }
    }
    &__more-text {
        padding-left: 50px;
        background-color: transparent;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 40%);
        font-size: 14px;
        color: $color-11st-blue;
        line-height: 18px;
        &:after {
            display: inline-block;
            margin: -1px 4px 1px 2px;
            vertical-align: middle;
            @include ico-arrow-right(7px, $color-11st-blue);
        }
    }
}

.c-prd-info {
    overflow: hidden;
    white-space: nowrap;
    font-size: 0; // 할인율 가격 사이 여백 초기화

    &__name {
        display: block;
        padding-top: 2px;
        margin-bottom: 4px;
        height: 36px;
        font-size: 14px;
        line-height: 18px;
        color: #111;
        @include text-ellipsis-multiple(2);
    }

    &__rate {
        display: inline-block;
        height: 18px;
        margin-top: 5px;
        padding-right: 4px;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.5px;
        color: $color-11st-red;
        vertical-align: top;

        strong {
            font-size: 16px;
            line-height: 18px;

            &.c-prd-info__special {
                font-size: 14px;
            }
        }
    }

    &__price {
        display: inline-block;
        margin-top: 5px;
        font-size: 12px;
        line-height: 18px;
        color: #111;
        letter-spacing: -0.5px;
        vertical-align: top;

        strong {
            display: inline-block;
            font-size: 16px;
            vertical-align: top;
        }

        del {
            display: block;
            height: 14px;
            color: #999;
            line-height: 14px;
        }
    }

    &__benefit {
        display: block;
        margin-top: 8px;
        padding-bottom: 4px;
        font-size: 12px;
        line-height: 16px;
        color: #999;
        letter-spacing: 0;
        white-space: nowrap;

        .gap + .gap {
            margin-left: 6px;
        }

        .benefit {
            display: block;
            &:first-child {
                padding-bottom: 2px;
            }
            em {
                color: $color-11st-blue;
            }
        }
    }

    &__meta {
        @include inline-definition(12);
        height: 16px;
        margin-top: 4px;
        letter-spacing: 0;
        white-space: nowrap;
    }

    &__qty {
        display: block;
        height: 16px;
        margin-top: 5px;
        font-size: 12px;
        line-height: 16px;
        color: #999;
        letter-spacing: 0;
        white-space: nowrap;
    }

    &__seller {
        font-size: 12px;
        color: #888;

        strong {
            color: #c29254;
            font-weight: normal;
            padding-right: 2px;
        }
    }

    .c-card__list & {
        margin-left: 144px;
        padding-left: 8px;

        .c-prd-info__qty {
            position: absolute;
            left: 152px;
            bottom: 0;
        }
    }
}

.c-prd-review {
    overflow: hidden;

    .c-card__list .c-card__thumb + & {
        margin-left: 144px;
        padding-left: 16px;
    }

    &__rate {
        @include sprite-retina($sp_review_star_gray);
        display: block;
    }

    &__rate-value {
        @include sprite-retina($sp_review_star_yellow);
        display: block;
    }

    &__name {
        display: block;
        height: 18px;
        margin-top: 7px;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #111;
        @include text-ellipsis-multiple(1);
    }

    &__option {
        display: block;
        height: 16px;
        margin-top: 12px;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        color: #999;
        @include text-ellipsis-multiple(1);
    }

    &__price {
        @include price(16);
        display: block;
        height: 20px;
        margin-top: 3px;
        color: #111;
        letter-spacing: 0;
    }

    &__comment {
        display: block;
        height: 48px;
        margin-top: 8px;
        font-size: 13px;
        line-height: 16px;
        color: #999;
        @include text-ellipsis-multiple(3);
    }

    &__comment19 {
        display: block;
        margin-top: 26px;
        font-size: 14px;
        line-height: 20px;
        color: #333;
        text-align: center;

        &:before {
            display: inline-block;
            margin: -1px 0 1px;
            vertical-align: middle;
            content: "";
            @include sprite-retina($sp_review_warning_s);
        }
    }

    &__badge {
        position: absolute;
        right: 0;
        top: 0;
        padding: 0 6px;
        border: 1px solid rgba(255, 69, 85, 0.4);
        border-radius: 8px;
        font-weight: bold;
        font-size: 10px;
        line-height: 14px;
        color: #ff4555;
    }

    &__badge19 {
        position: absolute;
        right: 0;
        top: 0;
        @include sprite-retina($sp_review_19_s);

        ~ .c-prd-review__name {
            margin-right: 45px;
        }
    }
}
@include thumbnail-playicon-large;
@include thumbnail-playicon-youtube;
@include thumbnail-replayicon;
