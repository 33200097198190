@import "../sprites/sp_review";

.c-imgcover {
    position: relative;
    overflow: hidden;
    padding-top: 100%;
    height: 0;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, transparent 50%, rgba(0, 0, 0, 0.28) 80%, rgba(0, 0, 0, 0.39));
    }

    > a {
        display: block;
        position: relative;
    }

    > img {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        min-width: 320px;
        vertical-align: top;
    }

    &__text {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 0 32px 32px;
        z-index: 5;

        @include box-sizing(border-box);

        p {
            margin-bottom: 18px;
            color: #fff;
            font-size: 22px;
            line-height: 20px;
        }

        p:last-child {
            margin: 0;
        }

        .c-imgcover__name {
            display: inline-block;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            border-bottom: 2px solid #fff;
        }

        .c-imgcover__info {
            display: block;
            padding-right: 40px;
            font-size: 13px;
            line-height: 17px;
            word-wrap: break-word;

            .c-imgcover__title {
                display: block;
                min-width: 150px;
                margin-bottom: 18px;
                font-size: 24px;
                font-weight: 300;
                line-height: 28px;
                word-break: keep-all;
            }
        }

        .c-imgcover__text__detail {
            display: block;
            height: 32px;
            width: 112px;
            margin-top: 32px;
            border: 1px solid #fff;
            color: #fff;
            text-align: center;
            font-size: 14px;
            line-height: 30px;
        }

        &--center {
            text-align: center;
            font-size: 36px;
            line-height: 34px;
            padding: 0 0 45px;
            font-family: "Helvetica";

            .c-imgcover__info {
                padding: 0;

                .c-imgcover__title {
                    width: auto;
                    margin-bottom: 6px;
                    font-weight: bold;
                    padding: 0 16px;
                    font-size: 36px;
                    line-height: 34px;
                }

                span {
                    display: block;
                    padding: 0 66px;
                }
            }
        }
    }

    &__bi {
        width: 80px;
        height: 80px;
        margin: 0 auto 16px auto;
        line-height: 80px;
        text-align: center;

        img {
            width: 100%;
        }
    }
}

.c-seller-bi {
    @include border-radius(40px);

    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
    z-index: 10;
    background: #fff;
    width: 80px;
    height: 80px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    text-align: center;
    line-height: 96px;

    img {
        @include size(100% 30px);

        @include border-radius(40px);
    }
}

.c-ico--arrow {
    display: block;
    position: relative;
    height: 34px;
    width: 34px;
    margin: 0 auto;
    border: 1px solid #fff;
    color: transparent;
    text-indent: -9999px;

    @include border-radius(17px);

    @include box-sizing(border-box);

    z-index: 5;

    &:before {
        position: absolute;
        right: 13px;
        top: 50%;
        content: '';
        display: block;
        width: 9px;
        height: 9px;
        margin-top: -1px;
        border-top: 1px solid #fff;
        border-right: 1px solid #fff;
        transform: rotate(45deg) translateY(-55%);
    }

    &:after {
        position: absolute;
        left: 50%;
        top: 50%;
        content: '';
        display: block;
        width: 15px;
        height: 1px;
        background-color: #fff;
        transform: translate(-50%, -50%);
    }
}

.c-cover {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #f3f3f3;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;

    img {
        visibility: hidden;
    }

    &.play {
        &:before {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            @include sprite-retina($sp_review_play);
            content: '';
        }
    }
}
