@import "../sprites/sp_icon";
.c-banner {
    &__notice {
        position: relative;
        padding: 0 0 0 16px;
        height: 39px;
        color: #333;
        background: linear-gradient(90deg, #8f89d2 0%, #93aaf2 100%);
    }
    &__keyword {
        @include border-radius(12px);
        padding: 3px 10px;
        font-size: 12px;
        color: $color-gray-14;
        background: transparent;
        border: 1px solid $color-gray-14;
        border-radius: 12px;
        margin-right: 4px;
    }
    &__title {
        @include text-ellipsis;
        display: block;
        padding-right: 40px;
        line-height: 39px;
        font-size: 14px;
        color: $color-gray-14;
    }
    &__close {
        @include size(39px);
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        display: inline-block;
        overflow: hidden;
        background: transparent;
        border: 0;
        color: transparent;
        &:before,
        &:after {
            @include rotate(45deg);
            background-color: #fff;
            position: absolute;
            top: 50%;
            left: 56%;
            content: "";
        }
        &:before {
            @include size(17px 1px);
            margin: -1px 0 0 -9px;
        }
        &:after {
            @include size(1px 17px);
            margin: -9px 0 0 -1px;
        }
    }
    &__link {
        display: block;
    }
    &__img {
        @include size(300px 60px);
        display: block;
        margin: 0 auto;
        &-coupon {
            @include size(300px 40px);
            display: block;
            margin: 0 auto;
        }
    }
    &__coupon {
        position: relative;
        display: block;
        padding: 0 0 0 16px;
        height: 39px;
        color: #333;
        background: -webkit-gradient(linear, left top, right top, from(#8f89d2), to(#93aaf2));
        background: -webkit-linear-gradient(left, #8f89d2 0%, #93aaf2 100%);
        background: linear-gradient(90deg, #8f89d2 0%, #93aaf2 100%);
        &:after {
            position: absolute;
            right: 16px;
            top: 50%;
            content: "";
            display: block;
            border-top: 1px solid rgba(0, 0, 0, 0.05);
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            width: 12px;
            height: 12px;
            margin-top: -6px;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
    &-coupon {
        display: block;
        padding: 10px;
        font-size: 0;
        color: #fff;
        letter-spacing: 0;
        text-align: center;
        .c-banner-coupon__rate {
            display: inline-block;
            min-width: 40px;
            position: relative;
            margin-left: 8px;
            padding: 2px 12px 2px 7px;
            border-radius: 3px;
            background-color: #fff;
            font-size: 12px;
            line-height: 16px;
            color: #666;
            letter-spacing: 0;
            text-align: center;
            overflow: hidden;
            vertical-align: top;
            em {
                font-weight: bold;
            }
            &:before {
                @include sprite-retina($sp_icon_coupon_back);
                position: absolute;
                right: 0;
                top: 0;
                border-radius: 0 3px 3px 0;
                content: "";
            }
            &:after {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                border: 1px solid rgba(0, 0, 0, 0.2);
                border-radius: 3px;
                content: "";
            }
        }
        .c-banner-coupon__text {
            display: inline-block;
            font-size: 14px;
            line-height: 20px;
            vertical-align: top;
        }
    }
}
