// 상원: 현재는 Masonry UI에서만 쓰이고 있습니다. 향후 공통 블럭으로 옮겨야 할지 논의가 필요합니다.
// 스켈레톤 UI
.c-skeleton {
    &__box,
    &__bar {
        background: linear-gradient(90deg, #f2f5f9 2%, #fafcff 60%, #f2f5f9 100%);
        animation: placeHolderShimmer 12s linear infinite forwards;
    }

    &__box {
        width: 100%;
        height: 100%;
        padding-bottom: 100%;
        border-radius: 8px;

        & + .c-skeleton__bar {
            margin-top: 12px;
        }
    }

    &__bar {
        $common-margin: 8px;
        width: calc(100% - #{$common-margin});
        height: 16px;
        margin: 0 4px;
        border-radius: 4px;

        & + .c-skeleton__bar {
            width: calc(75% - #{$common-margin});

            margin-top: 6px;
        }

        &:last-child {
            width: calc(91% - #{$common-margin});
        }
    }
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -500px 0;
    }
    100% {
        background-position: 500px 0;
    }
}
