.c-icontext {
    display: block;
    background-color: #fff;
    border-radius: 27px;

    &__icon {
        display: block;
        overflow: hidden;

        img {
            display: block;
            width: 100%;
        }
    }

    &__text {
        display: block;
        padding-top: 2px;
        text-align: center;
        font-size: 12px;
        color: #333;
        white-space: nowrap;
    }

    &--borderless {
        @include size(64px 56px);

        .c-icontext__icon {
            @include size(32px);

            min-width: 32px;
            border: 0;
            border-radius: 0;
            margin: 0 auto 1px;
        }

        .c-icontext__text {
            line-height: 16px;
            font-size: 13px;
            font-weight: normal;
            color: #333;
        }
    }

    &--description {
        @include size(104px 74px);
        margin-top: 1px;
        padding: 8px 0 0 0;

        &.c-icontext {
            border-radius: 0;
        }

        .c-icontext__icon {
            @include size(80px 30px);

            border: 0;
            border-radius: 0;
        }

        .c-icontext__text {
            line-height: 16px;
            font-size: 13px;
            font-weight: normal;
            color: #333;
        }
    }
}
