@charset "UTF-8";

@import "../helpers/variables";

@font-face {
    font-family: "BaseLine";
    src: local("Helvetica Neue");
    unicode-range: U+007F;
}

html {
    height: 100%;
}

body {
    background: $color-gray-14;
    color: $color-gray-04;
    font-size: 16px;
    letter-spacing: $body-spacing;
    overflow-wrap: break-word;
    -webkit-text-size-adjust: none;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
select,
textarea,
p,
blockquote,
th,
td,
button {
    margin: 0;
    padding: 0;
    font-family: $font-family;
}

section,
nav,
article,
aside,
hgroup,
header,
footer {
    display: block;
}

form,
fieldset,
img {
    border: 0 none;
}

table {
    border-spacing: 0;
    border: 0;
}

em {
    font-style: normal;
}

ul,
ol,
li {
    list-style: none outside;
}

input,
select {
    vertical-align: middle;
}

a {
    text-decoration: none;
    color: inherit;
}

// li, p, dt, dd, div, span {
//     word-break: break-all;
// }

legend,
caption,
.skip,
.hide {
    visibility: hidden;
    overflow: hidden;
    width: 0;
    height: 0;
    font-size: 0;
    line-height: 0;
    margin: 0;
    padding: 0;
}

input[type="text"],
input[type="password"],
input[type="tel"],
input[type="number"],
input[type="search"],
input[type="email"] {
    background: $color-gray-14;
    color: $color-gray-04;
    -webkit-appearance: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

button,
input[type="submit"],
input[type="button"] {
    cursor: pointer;
    -webkit-appearance: none;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
}

textarea {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

iframe {
    display: block;
}

#wrap {
    max-width: 1280px;
    margin: auto;
}

.sr-only {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    margin: -1px;
    width: 1px;
    height: 1px;
    font-size: 6px;
    line-height: 1;
    white-space: nowrap;
    user-select: none;
}

.l-grid {
    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    *:before,
    *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
}
