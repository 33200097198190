// Grid Mixin
$step: 4px;
$step2: $step * 2;
$blockBorderColor: #f4f4f4;

@mixin grid($y, $x, $border) {
    @if $border {
        & {
            padding: 0 ($x*$step);

            .l-grid__col {
                position: relative;
                padding: ($y*$step2) ($x*$step) ($y*$step2+1px);

                &:after {
                    position: absolute;
                    left: -($x*$step);
                    right: -($x*$step);
                    bottom: 0;
                    border-bottom: 1px solid $blockBorderColor;
                    content: "";
                }
            }

            &:nth-last-child(1) {
                margin-bottom: -1px;
            }
        }
    }

    @else {
        & {
            @if $x == $y {
                padding: ($y*$step);
            }
            @else {
                padding: ($y*$step) ($x*$step);
            }

            .l-grid__col {
                position: relative;

                @if $x == $y {
                    padding: ($y*$step);
                }
                @else {
                    padding: ($y*$step) ($x*$step);
                }
            }
        }
    }
}
