.c-layer {
    position: absolute;
    left: 16px;
    right: 16px;
    padding: 14px 16px;
    margin: 0 auto;
    border-radius: 4px;
    background: $color-gray-14;
    box-shadow: 0px 6px 24px -8px rgba(0, 0, 0, 0.12), 0px 0px 1px 0px rgba(0, 0, 0, 0.36);
    box-sizing: border-box;
    z-index: 30;

    &:before {
        content: "";
        float: right;
        width: 30px;
        height: 20px;
    }
    &__title {
        margin: 6px 0 15px;
        color: $color-gray-03;
        font-size: 15px;
        font-weight: bold;
        line-height: 18px;

        & ~ .c-layer__close {
            top: 14px;
        }
        & ~ .c-layer__content {
            margin-bottom: 6px;
        }
    }

    &__content {
        color: $color-gray-04;
        font-size: 14px;
        line-height: 1.215;
    }

    &__close {
        @include size(30px);

        overflow: hidden;
        position: absolute;
        top: 9px;
        right: 7px;
        border: none;
        background: none;
        color: transparent;
        text-indent: -30px;

        &:before,
        &:after {
            @include size(1px 17px);

            content: "";
            position: absolute;
            left: auto;
            right: 50%;
            top: 50%;
            margin: -9px 0 0 -1px;
            background: #333;
        }

        &:before {
            @include rotate(45deg);
        }

        &:after {
            @include rotate(-45deg);
        }
    }
}
