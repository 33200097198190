.c-listimg {
    overflow: hidden;
    width: 100%;
    margin-bottom: -1px;

    &__item {
        display: block;
        float: left;
        box-sizing: border-box;
        margin: 0;
        width: 25%;

        &:nth-child(4n + 1) {
            clear: both;
        }

        &:nth-child(4n) a:after {
            border-right-width: 0;
        }

        & {
            @include mq-640 {
                width: 12.5%;

                &:nth-child(4n + 1) {
                    clear: none;
                }
            }
        }

        .c-listimg__link {
            display: block;
            position: relative;
            width: 100%;
            color: $color-gray-03;

            > img {
                display: block;
                width: 100%;
            }

            .c-listimg__icon-wrap {
                @include size(32px);

                position: relative;
                display: block;
                margin: 0 auto;

                img {
                    display: block;
                    width: 100%;

                    &.c-listimg__icon {
                        @include size(32px 32px);

                        margin: 0 auto;
                    }
                }
            }

            .c-listimg__name {
                box-sizing: border-box;
                display: block;
                font-size: 12px;
                text-align: center;
                white-space: nowrap;
            }

            .c-listimg__title {
                @include vertical-middle(13px);

                position: absolute;
                left: 5px;
                right: 5px;
                bottom: 10%;
                color: $color-gray-14;
                font-size: 12px;
                text-align: center;
                z-index: 1;
                overflow: hidden;
                display: block;
            }

            &:after {
                content: "";
                display: none;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                border: 1px solid $color-gray-14;
                border-width: 0 1px 1px 0;
            }
        }

        &.on {
            .c-listimg__link {
                background: $color-11st-red;
                color: #fff;
            }
        }

        &:last-child {
            .c-listimg__link {
                position: relative;

                .c-listimg__more {
                    @include size(100% 100%);

                    background-color: rgba(0, 0, 0, 0.7);
                    position: absolute;
                    display: block;

                    &-text {
                        @include size(100% 48px);

                        display: block;
                        position: absolute;
                        top: 50%;
                        margin-top: -24px;
                        font-size: 13px;
                        color: $color-gray-07;
                        text-align: center;

                        .c-listimg__qty {
                            display: block;
                            color: $color-gray-14;
                            font-size: 28px;
                        }
                    }
                }
            }
        }
    }

    &--icon {
        .c-listimg__item {
            .c-listimg__link {
                box-sizing: border-box;
                padding: 8px 0;
                border: 1px solid #f4f4f4;
                border-width: 0 1px 1px 0;

                &:after {
                    border: 0;
                }
            }
        }
    }
}
