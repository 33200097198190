[data-type="Contents_MyReco"] {
    .c-banner {
        &__notice {
            padding-top: 15px;
            height: 72px;
            line-height: initial;
            .c-banner__title {
                font-size: 15px;
                font-weight: 300;
                line-height: initial;
                letter-spacing: 0;
                &-reco {
                    display: block;
                    font-size: 17px;
                    line-height: initial;
                }
                em {
                    font-weight: 600;
                }
            }
        }
        &__close {
            top: 17px;
            right: 6px;
        }
        &__login {
            background: none;
            .c-banner__title {
                p {
                    color: #333;
                    font-weight: normal;
                }
            }
            .btn-login {
                color: #2cb1f8;
                border: 1px solid #2cb1f8;
                text-align: center;
                display: block;
                height: 32px;
                width: 65px;
                position: absolute;
                right: 17px;
                top: 50%;
                margin-top: -16px;
                line-height: 32px;
                font-size: 13px;
                font-weight: bold;
                @include border-radius(16px);
            }
        }
    }
}