.c-magnet {
    &--sticky {
        position: sticky !important;
        top: 0;

        &.is--active {
            & > :nth-child(1) {
                width: 100%;
                transform: translateZ(0);
            }
        }
    }

    &--fixed {
        &.is--active {
            position: relative;

            & > :nth-child(1) {
                position: fixed;
            }
        }
    }
}
.c-magnet-multiple {
    &--fixed {
        position: fixed;
        z-index: 100;
        width: 100%;
        max-width: 1280px;
        background-color: $color-gray-14;
    }
    &--absolute {
        position: absolute;
    }
}
.w-magnet {
    width: 100%;
    max-width: 1280px;
    background-color: $color-gray-14;
}
.header-sticky {
    width: 100%;
    max-width: 1280px;
}
