@import "../../common/sprites/sp_eui";
@mixin thumbnail-playicon {
    .c-badge-play {
        @include size(24px);
        @include sprite-retina($sp_eui_play_s);
        display: block;
        position: absolute;
        left: initial;
        top: initial;
        transform: initial;
        right: 4px;
        bottom: 4px;
        z-index: 2;
        text-indent: -99999px;
    }
}
