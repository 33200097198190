.c-profile {
    display: block;
    position: relative;
    padding-right: 120px;
    letter-spacing: $body-spacing;

    &__pic {
        position: relative;
        display: block;
        float: left;
        margin-right: 9px;
        width: 36px;
        height: 36px;

        span {
            @include size(36px);
            display: block;
            overflow: hidden;
            position: relative;
            z-index: 2;
            margin: 0 auto;
            border-radius: 50%;
            background-size: cover;
            background-position: 50% 50%;
            text-indent: -99999px;

            &.profile1 {
                background-image: url("/MW/img/pui/sprites/kkuk/profile_1.png");
            }
            &.profile2 {
                background-image: url("/MW/img/pui/sprites/kkuk/profile_2.png");
            }
            &.profile3 {
                background-image: url("/MW/img/pui/sprites/kkuk/profile_3.png");
            }
            &.profile4 {
                background-image: url("/MW/img/pui/sprites/kkuk/profile_4.png");
            }
            &.profile5 {
                background-image: url("/MW/img/pui/sprites/kkuk/profile_5.png");
            }
        }
        &:after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 1px solid rgba(92, 30, 30, 0.04);
            border-radius: 24px;
            content: "";
        }
        .c-lazyload {
            &::before {
                background: none;
            }
        }
    }
    &__name {
        display: block;
        overflow: hidden;
        height: 36px;
        font-size: 14px;
        font-weight: normal;
        color: $color-gray-02;
        line-height: 39px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    &__badge {
        display: inline-block;
        margin-left: 6px;
        font-size: 11px;
        font-weight: normal;
        color: $color-11st-blue;
    }
    &__other {
        margin-top: 2px;
        font-size: 11px;
        color: $color-gray-07;

        strong {
            font-weight: normal;
        }
        span,
        button {
            position: relative;
            margin-left: 8px;

            &:before {
                position: absolute;
                top: 49%;
                left: -7px;
                width: 2px;
                height: 2px;
                border-radius: 100%;
                background: $color-gray-09;
                content: "";
            }
            &:first-child {
                margin-left: 0;

                &:before {
                    display: none;
                }
            }
        }
        button {
            border: 0;
            background: none;
            font-size: 11px;
            color: $color-gray-07;
        }
    }
    &__info {
        display: block;
        padding: 1px 0 2px 45px;
        font-size: $font-size-caption2;
        color: $color-gray-07;

        li {
            display: inline;
            position: relative;
            padding-right: 3px;

            strong {
                display: inline-block;
                padding: 0 5px;
                font-weight: normal;
                color: $color-11st-red;
                height: 18px;
                line-height: 18px;
                text-align: center;
                border: 1px solid rgba(244, 49, 66, 0.2);
            }
            .link-box {
                display: inline-block;
                position: relative;
                height: 18px;
                padding: 0 12px 0 5px;
                border: 1px solid rgba(11, 131, 230, 0.2);
                color: $color-11st-blue;
                line-height: 18px;

                &:after {
                    @include ico-arrow-right(6px, $color-11st-blue, 1px);
                    position: absolute;
                    top: 5px;
                    right: 5px;
                }
            }
        }
    }
    &.tooltip {
        .c-tooltip {
            display: block;
        }
    }
    .c-tooltip {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 5;
        height: 30px;
        margin-top: 10px;
        padding: 0 10px;
        border-radius: 2px;
        background-color: $color-gray-14;
        font-size: 12px;
        color: $color-gray-04;
        line-height: 30px;
        box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.4);

        strong {
            color: $color-11st-blue;
        }
        &:before {
            display: block;
            position: absolute;
            top: -5px;
            left: 16px;
            z-index: 1;
            width: 6px;
            height: 8px;
            border: solid #e8e8e8;
            border-width: 1px 1px 0 0;
            background-color: $color-gray-14;
            transform: rotate(314deg);
            content: "";
        }
    }
    .c-button--share {
        position: absolute;
        top: 0;
        right: 0;
    }
    .c-count-view {
        right: 0;
    }
    .c-starrate {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }
}
.c-count-view {
    position: absolute;
    top: 50%;
    right: 16px;
    font-size: 13px;
    color: $color-gray-06;
    letter-spacing: $body-spacing;
    transform: translateY(-50%);
}
