.c-ad__info {
    display: none;
    position: fixed;
    z-index: 999;
    top: 50%;
    width: 280px;
    padding: 16px;
    background: $color-gray-14;
    letter-spacing: $body-spacing;
    box-sizing: border-box;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);

    &--title {
        color: #333;
        font-size: 15px;
        line-height: 24px;
        height: 24px;
        margin-bottom: 8px;
    }
    &--con {
        color: #666;
        font-size: 14px;
        line-height: 17px;
    }

    .c-ad__btn--close {
        position: absolute;
        top: 10px;
        right: 10px;
        @include size(24px);
        background-color: transparent;
        color: transparent;
        border: 0;
        &:before {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            width: 1px;
            height: 17px;
            margin: -8px 0 0 0;
            background: #333;
            transform: rotate(45deg);
        }
        &:after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            width: 1px;
            height: 17px;
            margin: -8px 0 0 0;
            background: #333;
            transform: rotate(45deg);
            width: 17px;
            height: 1px;
            margin: 0 0 0 -8px;
        }
    }
}
