@mixin thumbnail-playtime {
    .c-badge-time {
        position: absolute;
        bottom: 8px;
        left: 8px;
        z-index: 2;
        padding: 1px 3px;
        font-size: 12px;
        font-weight: bold;
        color: $color-gray-14;
        line-height: 1;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            border-radius: 5px;
            background: rgba($color: $color-gray-01, $alpha: 0.24);
            filter: blur(4px);
        }
    }
}
