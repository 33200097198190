.slot-machine {
    font-size: 50px;
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    position: relative;
    .slot-machine-digit {
        display: inline-block;
        vertical-align: middle;
        *vertical-align: auto;
        *zoom: 1;
        *display: inline;
        position: relative;
        .slot-machine-digit-spacer {
            display: inline-block;
            vertical-align: middle;
            *vertical-align: auto;
            *zoom: 1;
            *display: inline;
            visibility: hidden;
        }
        .slot-machine-digit-inner {
            text-align: left;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: hidden;
        }
        .slot-machine-value-group {
            display: block;
            -webkit-backface-visibility: hidden;
        }
        .slot-machine-value {
            display: block;
            -webkit-transform: translateZ(0);
            &.slot-machine-value-last {
                position: absolute;
            }
        }
    }
    &.slot-machine-animating-up {
        .slot-machine-value-group {
            -webkit-transition: -webkit-transform 2s;
            -moz-transition: -moz-transform 2s;
            -ms-transition: -ms-transform 2s;
            -o-transition: -o-transform 2s;
            transition: transform 2s;
        }
        &.slot-machine-animating {
            .slot-machine-value-group {
            -webkit-transform: translateY(-100%);
            -moz-transform: translateY(-100%);
            -ms-transform: translateY(-100%);
            -o-transform: translateY(-100%);
            transform: translateY(-100%);
        }
        }

    }
    &.slot-machine-animating-down {
        .slot-machine-value-group {
            -webkit-transform: translateY(-100%);
            -moz-transform: translateY(-100%);
            -ms-transform: translateY(-100%);
            -o-transform: translateY(-100%);
            transform: translateY(-100%);
        }
        &.slot-machine-animating {
            .slot-machine-value-group {
                -webkit-transition: -webkit-transform 2s;
                -moz-transition: -moz-transform 2s;
                -ms-transition: -ms-transform 2s;
                -o-transition: -o-transform 2s;
                transition: transform 2s;
                -webkit-transform: translateY(0);
                -moz-transform: translateY(0);
                -ms-transform: translateY(0);
                -o-transform: translateY(0);
                transform: translateY(0);
            }
        }
    }
    .slot-machine-value {
        text-align: center;
    }
}
