@mixin price($font-size) {
    font-size: $font-size - 4 + px;
    line-height: $font-size + 4 + px;
    white-space: nowrap;

    strong {
        display: inline-block;
        font-size: $font-size + px;
        letter-spacing: -0.5px;
        vertical-align: top;
    }
}
