[data-type="ProductGrid_ImgOverPrice"] {
    @include grid(1, 1, false);
    .c-card__counsel {
        position: relative;
        font-size: 14px;
        font-weight: bold;
        padding: 0;
        em {
            color: #fff;
        }
    }
}
