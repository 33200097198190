@import "../sprites/sp_components";

.c-dropdown {
    @include size(100% 40px);

    position: relative;
    padding: 0 40px 0 12px;
    border: 1px solid $color-gray-09;
    background: url("/MW/img/pui/ico_dropdown.png") no-repeat 100% 0;
    background-size: 41px 40px;
    font-size: 15px;
    line-height: 15px;
    color: $color-gray-02;
    border-radius: 4px;

    &.disabled {
        border-color: rgba($color: #ccc, $alpha: 0.2);
        background-color: $color-gray-13;
        background-image: url("/MW/img/pui/ico_dropdown_disabled.png");
        color: $color-gray-09;
    }
}
