.f-scroll-disabled {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}

html.f-scroll-disabled-ios15 {
    overflow: hidden;
    height: 100vh;
}