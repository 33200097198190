// interaction 약자 : itr
// [TODO] mixin 따로 빼기
// 텍스트 애니메이션 mixin
@mixin itr-text($duration: 5s, $steps: 500) {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    animation: itrTextAnimation $duration steps($steps) infinite;

    @keyframes itrTextAnimation {
        from {
            width: 0px;
        }
    }
}

// 그라데이션 애니메이션 mixin
@mixin itr-gradation($color-start, $color-middle, $color-end, $duration) {
    background-image: linear-gradient(to right, $color-start, $color-middle 52%, $color-end);
    animation: itrGradation $duration infinite linear;

    @keyframes itrGradation {
        0% {
            filter: hue-rotate(0deg);
        }
        100% {
            filter: hue-rotate(360deg);
        }
    }
}

// 찜 버튼 애니메이션 mixin
@mixin itr-like-btn($size, $color, $dot-size) {
    display: flex;
    justify-content: center;
    align-items: center;
    --c: #{$color};

    &__svg {
        position: relative;
        width: $size;
    }
    &__path {
        fill: #eee;
        stroke: var(--c);
        stroke-width: $size;
        stroke-dasharray: 3000;
        stroke-dashoffset: 3000;
    }
    &__dot {
        position: absolute;
        top: 50%;
        left: 50%;
        width: $dot-size;
        height: $dot-size;
        margin-top: -5px;
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        box-shadow: 0 -30px 0 var(--c), 0 30px 0 var(--c), -30px 0 0 var(--c), 30px 0 0 var(--c), -20px -20px 0 var(--c), 20px -20px 0 var(--c), 20px 20px 0 var(--c), -20px 20px 0 var(--c);
    }
    &__input:checked {
        + svg {
            animation: itrLikeBeat 1s linear forwards;

            .itr-like__path {
                animation: itrLikeDrawHeart 1s linear forwards;
            }
        }

        ~ .itr-like__dot {
            animation: itrLikeBlink 0.5s ease-in-out forwards;
            animation-delay: 0.8s;
        }
    }
    @keyframes itrLikeBeat {
        0% {
            transform: scale(1);
        }
        70% {
            transform: scale(1);
        }
        80% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes itrLikeDrawHeart {
        0% {
            stroke-dashoffset: 2600;
        }
        80% {
            fill: #eee;
            stroke-dashoffset: 0;
        }
        100% {
            fill: var(--c);
            stroke-dashoffset: 0;
        }
    }
    @keyframes itrLikeBlink {
        0% {
            transform: translate(-50%, -50%) scale(0.5);
            opacity: 0.8;
        }
        80% {
            transform: translate(-50%, -50%) scale(1);
            opacity: 1;
        }
        100% {
            transform: translate(-50%, -50%) scale(1.1);
            opacity: 0;
        }
    }
}
// 별점 애니메이션 mixin
@mixin itr-star-review {
    &__wrap {
        float: left;
        transform: scale(0.5);
        margin: -16px -36px -16px -46px;
    }

    &__rating {
        position: relative;
        display: flex;
        padding-bottom: 6px;
    }

    &__star {
        display: block;
        overflow: visible;
        pointer-events: none;
        width: 32px;
        height: 32px;

        &-fill,
        &-ring,
        &-line,
        &-stroke {
            animation-duration: 1s;
            animation-timing-function: ease-in-out;
            animation-fill-mode: forwards;
        }

        &-fill,
        &-ring,
        &-line {
            stroke: #f4a825;
        }

        &-fill {
            fill: #f4a825;
            transform: scale(0);
            transition: fill var(--trans-dur) var(--bezier), transform var(--trans-dur) var(--bezier);
        }

        &-line {
            stroke-dasharray: 12 13;
            stroke-dashoffset: -13;
        }

        &-stroke {
            stroke: #c7cad1;
            transition: stroke var(--trans-dur);
        }
    }

    &__label {
        padding: 2px;
    }

    &__input {
        position: absolute;
        appearance: none;

        $star-count: 5;
        @for $i from 1 through $star-count {
            &-#{$i}:checked ~ .itr-review__label:nth-of-type(-n + #{$i}) .itr-review__star-ring {
                animation-name: itrStarRing;
            }

            &-#{$i}:checked ~ .itr-review__label:nth-of-type(-n + #{$i}) .itr-review__star-stroke {
                animation-name: itrStarStroke;
            }

            &-#{$i}:checked ~ .itr-review__label:nth-of-type(-n + #{$i}) .itr-review__star-line {
                animation-name: itrStarLine;
            }

            &-#{$i}:checked ~ .itr-review__label:nth-of-type(-n + #{$i}) .itr-review__star-fill {
                animation-name: itrStarFill;
            }
        }
    }

    // Keyframes
    @keyframes itrStarRing {
        0%,
        20% {
            animation-timing-function: ease-in;
            opacity: 1;
            stroke-width: 16px;
            transform: scale(0);
        }
        35% {
            animation-timing-function: ease-out;
            opacity: 0.5;
            stroke-width: 16px;
            transform: scale(1);
        }
        50%,
        to {
            opacity: 0;
            stroke-width: 0;
            transform: scale(1);
        }
    }

    @keyframes itrStarFill {
        0%,
        40% {
            animation-timing-function: ease-out;
            transform: scale(0);
        }
        60% {
            animation-timing-function: ease-in-out;
            transform: scale(1.2);
        }
        80% {
            transform: scale(0.9);
        }
        to {
            transform: scale(1);
        }
    }

    @keyframes itrStarStroke {
        0% {
            transform: scale(1);
        }
        20%,
        to {
            transform: scale(0);
        }
    }

    @keyframes itrStarLine {
        0%,
        40% {
            animation-timing-function: ease-out;
            stroke-dasharray: 1 23;
            stroke-dashoffset: 1;
        }
        60%,
        to {
            stroke-dasharray: 12 13;
            stroke-dashoffset: -13;
        }
    }
}

// 상품 카드 라인 애니메이션 mixin
@mixin itr-card-line($line-thickness, $color) {
    position: absolute;
    z-index: 10;

    &:nth-child(1) {
        top: 0;
        left: 0;
        width: 100%;
        height: $line-thickness;
        background: linear-gradient(90deg, transparent, $color);
        animation: itrMoveFromLeft 2s linear infinite;
    }
    &:nth-child(2) {
        top: -100%;
        right: 0;
        width: 5px;
        height: 100%;
        background: linear-gradient(180deg, transparent, $color);
        animation: itrMoveFromTop 2s linear infinite 0.25s;
    }
    &:nth-child(3) {
        bottom: 0;
        right: 0;
        width: 100%;
        height: $line-thickness;
        background: linear-gradient(270deg, transparent, $color);
        animation: itrMoveFromRight 2s linear infinite;
        animation-delay: 0.5s;
    }
    &:nth-child(4) {
        bottom: -100%;
        left: 0;
        width: $line-thickness;
        height: 100%;
        background: linear-gradient(360deg, transparent, $color);
        animation: itrMoveFromBottom 2s linear infinite;
        animation-delay: 0.75s;
    }
    @keyframes itrMoveFromLeft {
        0% {
            left: -100%;
        }
        50%,
        100% {
            left: 100%;
        }
    }
    @keyframes itrMoveFromTop {
        0% {
            top: -100%;
        }
        50%,
        100% {
            top: 100%;
        }
    }
    @keyframes itrMoveFromRight {
        0% {
            right: -100%;
        }
        50%,
        100% {
            right: 100%;
        }
    }
    @keyframes itrMoveFromBottom {
        0% {
            bottom: -100%;
        }
        50%,
        100% {
            bottom: 100%;
        }
    }
}

.itr-text {
    @include itr-text(3s, 300); //duration, steps
}
.itr-gradation {
    @include itr-gradation(#ef4523, #ff0038, #ee1e86, 6s); // color-start, color-middle, color-end, duration
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 1;
    width: 80px;
    height: 30px;
    font-size: 12px;
    color: #fff;
    line-height: 30px;
    text-align: center;
    border-radius: 15px;
}
.itr-like {
    @include itr-like-btn(40px, #ff6b81, 10px); // size, color, dot-size
    position: absolute;
    bottom: 10px;
    right: 20px;
    height: 40px;
}
.itr-review {
    @include itr-star-review;
}
.itr-line {
    &:nth-child(1) {
        @include itr-card-line(8px, #00ffff); // line-thickness, color, duration
    }
    &:nth-child(2) {
        @include itr-card-line(8px, #00ffff);
    }
    &:nth-child(3) {
        @include itr-card-line(8px, #00ffff);
    }
    &:nth-child(4) {
        @include itr-card-line(8px, #00ffff);
    }
}
