@import "../../common/sprites/sp_eui";
@mixin thumbnail_playicon_youtube {
    .c-card__mov__youtube,
    .c-card-item__mov__youtube {
        @include size(64px 22px);
        @include dimned-opacity();
        position: absolute;
        top: 8px;
        right: 8px;
        display: block;
        border-radius: 1.5px;
        border: solid 1px rgba(0, 0, 0, 0.1);
        text-indent: -99999px;
        z-index: 1;
        &:after {
            @include size(58px 16px);
            @include sprite-retina($sp_eui_youtube);
            content: '';
            display: block;
            position: absolute;
            top: 2px;
            left: 2px;
        }
    }
}
