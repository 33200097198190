.c-ad__btn {
    border: 0 none;
    z-index: 99;
    display: block;
    color: transparent;
    background-color: transparent;
    outline: none;

    @include sprite-retina($sp_icons_ad_link_text);

    &.c-ad__btn--icon {
        @include sprite-retina($sp_icons_ad_link);
    }
    &.c-ad__btn--box {
        @include sprite-retina($sp_icons_ad_link_box);
    }

}

