.c-badge {
    @include border-radius(8px);

    position: absolute;
    top: 0;
    left: 70%;
    height: 16px;
    min-width: 16px;
    padding: 0 6px 0 5px;
    background: $color-11st-red;
    font-size: 12px;
    font-family: Helvetica;
    text-align: center;
    text-indent: 0;
    line-height: 16px;
    white-space: nowrap;
    color: $color-gray-14;
    box-sizing: border-box;
}
