// 슈팅배송
@mixin retail-delivery {
    .shooting-delivery {
        @include retail-common("shooting_delivery", 55);

        strong {
            margin: -1px 2px 1px 0;
        }
        span {
            &:before {
                @include separators-dot(2px, 2px, $color-gray-09);
                display: inline-block;
                margin: -1px 4px 1px 2px;
                vertical-align: middle;
                content: "";
            }
        }
    }
}
