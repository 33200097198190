@import "../sprites/sp_components";

.c-subcategory {
    overflow: hidden;
    position: relative;
    width: 100%;
    background: $color-gray-14;

    li {
        width: 100%;

        .c-subcategory__link {
            @include text-ellipsis;

            display: block;
            position: relative;
            height: 40px;
            line-height: 40px;
            padding: 0 25px 0 15px;
            color: $color-gray-03;
            font-size: 15px;

            &.on {
                color: $color-11st-red;

                &:after {
                    @include sprite-retina($sp_components_chk_red);

                    display: block;
                    content: "";
                    position: absolute;
                    top: 8px;
                    right: 10px;
                }
            }
        }
    }

    &__column2 {
        li {
            float: left;
            width: 50%;
            box-sizing: border-box;
        }

        &:after {
            content: "";
            clear: both;
        }
    }

    &__column3 {
        li {
            float: left;
            width: 33.333%;
            box-sizing: border-box;
        }
        &:after {
            content: "";
            clear: both;
        }
    }

    &--border {
        border-top: 1px solid $color-gray-12;
        margin-bottom: -2px;

        li {
            border-right: 1px solid $color-gray-12;
            border-bottom: 1px solid $color-gray-12;

            &:nth-child(even) {
                border-right: 0;
            }
        }

        &:after {
            @include size(100% 1px);

            position: absolute;
            left: 0;
            bottom: 0;
            content: "";
            background: $color-gray-12;
        }
    }
}
