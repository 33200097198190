@import "../../common/sprites/sp_eui";

.c-pagination {
    text-align: center;

    &__list {
        display: flex;
        padding: 8px 0;
        text-align: center;
        letter-spacing: $body-spacing;
        justify-content: space-between;

        li {
            display: inline-block;
            font-size: 14px;
            text-align: center;

            a {
                @include size(32px 32px);
                display: block;
                font-size: 15px;
                color: $color-gray-04;
                line-height: 32px;
                text-align: center;
            }

            &.c-pagination__previous,
            &.c-pagination__next {
                a {
                    @include border-radius(2px);
                    position: relative;
                    margin: 0;
                    text-indent: -99999px;

                    &:after {
                        @include size(7px);
                        position: absolute;
                        top: 50%;
                        margin-top: -4px;
                        border: 1px solid $color-gray-07;
                        border-width: 1px 1px 0 0;
                        content: "";
                    }

                    &.disable {
                        cursor: default;

                        &:after {
                            border-color: rgba(0, 0, 0, 0.13);
                        }
                    }
                }
            }

            &.c-pagination__previous {
                padding-left: 12px;

                @media screen and (max-width: 359px) {
                    padding-right: 12px;
                }

                a {
                    &:after {
                        @include rotate(223deg);
                        left: 50%;
                        margin-left: -4px;
                    }
                }
            }

            &.c-pagination__next {
                padding-right: 12px;

                @media screen and (max-width: 359px) {
                    padding-left: 12px;
                }

                a {
                    &:after {
                        @include rotate(45deg);
                        right: 50%;
                        margin-right: -4px;
                    }
                }
            }

            &.c-pagination__active {
                a {
                    font-weight: bold;
                    color: $color-11st-red;
                }
            }
        }
    }

    &__medium {
        .c-pagination__list {
            display: block;
            padding: 0;
        }
        span {
            display: inline-block;
            padding: 0 2px;

            &:first-child {
                padding-left: 7px;
            }

            &:last-child {
                padding-right: 7px;
            }
        }
        li.c-pagination__first,
        li.c-pagination__last,
        li.c-pagination__previous,
        li.c-pagination__next {
            padding: 0;
        }
        .c-pagination__first,
        .c-pagination__last,
        .c-pagination__previous,
        .c-pagination__next {
            a {
                &:before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin: -12px 0 0 -12px;
                    content: "";
                }
                &:after {
                    display: none;
                }
                &.disable {
                    opacity: 0.4;
                    cursor: default;
                }
            }
        }
        .c-pagination__first {
            a {
                &:before {
                    @include sprite-retina($sp_eui_forward);
                }
            }
        }
        .c-pagination__last {
            a {
                &:before {
                    @include sprite-retina($sp_eui_backward);
                }
            }
        }
        .c-pagination__previous {
            a {
                &:before {
                    @include sprite-retina($sp_eui_prev);
                }
            }
        }
        .c-pagination__next {
            a {
                &:before {
                    @include sprite-retina($sp_eui_next);
                }
            }
        }
        .c-pagination__active {
            font-weight: bold;
            color: $color-11st-red;

            &--convert {
                color: $color-11st-blue;
            }
        }
    }

    &__small {
        .c-pagination__list {
            @include border-radius(12px);

            display: inline-block;
            position: relative;
            height: 24px;
            padding: 0 10px;
            margin: 0 auto;
            background-color: rgba(0, 0, 0, 0.5);
            font-size: $font-size-body1;
            color: $color-gray-14;
            line-height: 25px;

            .c-pagination__previous,
            .c-pagination__next {
                display: none;
            }
        }
    }

    &__text {
        .c-pagination__list {
            display: block;
            padding: 0;
            font-size: 12px;

            .c-pagination__active {
                font-weight: bold;
            }
        }
    }

    &__indicator {
        display: block;
        font-size: 0;
        line-height: 36px;
        text-align: center;

        span {
            @include size(8px);
            @include border-radius(100%);

            display: inline-block;
            border: 1px solid $color-gray-14;
            background: $color-gray-01;
            vertical-align: middle;
            opacity: 0.3;
            box-sizing: border-box;

            &.on {
                opacity: 1;
            }
        }

        span + span {
            margin-left: 6px;
        }

        &.c-pagination__secondary {
            span {
                &.on {
                    background-color: $color-11st-red;
                }
            }
        }

        .c-pagination__bullet-movie {
            @include size(18px);
            position: relative;

            &:after {
                position: absolute;
                top: 50%;
                left: 50%;
                border-top: 3px solid transparent;
                border-bottom: 3px solid transparent;
                border-left: 5px solid $color-gray-14;
                transform: translate(-30%, -50%);
                content: "";
            }
        }
    }

    [data-type="BillBoard"] & {
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 1;
    }
}
