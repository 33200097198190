.c-noinfo {
    padding: 26px 0;
    min-height: 96px;
    line-height: 24px;
    text-align: center;
    font-size: 13px;
    color: #999;
    background: #fff;
    box-sizing: border-box;
    &__content {
        display: table;
        height: 100%;
        width: 100%;
    }

    &__text {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        strong {
            display: inline-block;
            font-size: 15px;
            color: #333;
            font-weight: normal;
        }

        p {
            margin-top: 5px;
            font-size: 13px;
            line-height: 17px;
            color: #999;
        }
    }
}
