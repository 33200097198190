@import "../../common/sprites/sp_eui";

@mixin thumbnail-replayicon {
    .c-card__mov__button-replay,
    .c-card-item__mov__button-replay {
        @include size(100%);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        text-indent: -99999px;

        &:before {
            @include size(60px 60px);
            @include sprite-retina($sp_eui_replay);
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -30px 0 0 -30px;
        }
    }
}
