.animated {
    animation-duration: 0.4s;
    animation-fill-mode: backwards;
}

.animated-for {
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
}

.animated-both {
    animation-duration: 0.4s;
    animation-fill-mode: both;
}

.modal-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modal-top {
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
}

.modal-bottom {
    top: 100%;
    left: 50%;
    transform: translate(-50%, -100%);
}

.modal-left {
    top: 0;
    left: 0;
}

.modal-right {
    top: 0;
    left: 100%;
    transform: translate(-100%, 0);
}

.fadeIn {
    animation-name: fadeIn;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeOut {
    animation-name: fadeOut;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.fadeInRight {
    animation-name: fadeInRight;
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translate3d(0, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(-100%, 0, 0);
    }
}

.fadeInLeft {
    animation-name: fadeInLeft;
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.fadeOutRight {
    animation-name: fadeOutRight;
}

@keyframes fadeOutRight {
    from {
        opacity: 1;
        transform: translate3d(-100%, 0, 0);
    }

    to {
        opacity: 0;
        transform: translate3d(0, 0, 0);
    }
}

.fadeOutLeft {
    animation-name: fadeOutLeft;
}

@keyframes fadeOutLeft {
    from {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    to {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }
}

.slideInTop {
    animation-name: slideInTop;
}

@keyframes slideInTop {
    from {
        transform: translate(-50%, -100%);
    }

    to {
        transform: translate(-50%, 0);
    }
}

.slideInBottom {
    animation-name: slideInBottom;
}

@keyframes slideInBottom {
    from {
        transform: translate(-50%, 0);
    }

    to {
        transform: translate(-50%, -100%);
    }
}

.slideOutBottom {
    animation-name: slideOutBottom;
}

@keyframes slideOutBottom {
    from {
        transform: translate(-50%, -100%);
    }

    to {
        transform: translate(-50%, 0);
    }
}

.slideOutTop {
    animation-name: slideOutTop;
}

@keyframes slideOutTop {
    from {
        transform: translate(-50%, 0);
    }

    to {
        transform: translate(-50%, -100%);
    }
}

.slideInLeft {
    animation-name: slideInLeft;
}

@keyframes slideInLeft {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}

.slideInRight {
    animation-name: slideInRight;
}

@keyframes slideInRight {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

.slideOutRight {
    animation-name: slideOutRight;
}

@keyframes slideOutRight {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}

.slideOutLeft {
    animation-name: slideOutLeft;
}

@keyframes slideOutLeft {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}
