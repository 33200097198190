//이미지 가로x세로 비율이 정수로 떨어지지 않는 경우, 아래 변수에 값을 추가

$lazyload-rates: (720, 600), (720, 500), (720, 405), (680, 380), (187, 280), (720, 994), (504, 283), (446, 252), (300, 100), (360, 100), (300, 60), (300, 80), (160, 60), (720, 300), (160, 96), (104, 136), (340, 72), (152, 96), (300, 44), (106, 142), (300, 400), (156, 208), (360, 210), (90, 120), (328, 208), (300, 40), (3, 1), (160, 80), (360, 158), (360, 150), (200, 170), (140, 114), (158, 200), (360, 270), (360, 400), (720, 240), (80, 30), (160, 200), (222, 267), (104, 130), (104, 131), (104, 132), (104, 80), (104, 72), (104, 56), (122, 82), (222, 130), (94, 62), (360, 160), (360, 240), (296, 166), (328, 160), (296, 160), (296, 131), (158, 106), (166, 114), (350, 624), (16, 9), (3, 4), (4, 3), (152, 228), (343, 192), (343, 146), (100, 150), (360, 136), (343, 235), (343, 166), (130, 73),
    (167, 94), (151, 84), (320, 60), (333, 286), (165, 250), (79, 50), (375, 164), (360, 164), (152, 190), (96, 144), (64, 80);

@each $rates in $lazyload-rates {
    $h: nth($rates, 1);
    $v: nth($rates, 2);
    .c-lazyload--ratio_#{$h}x#{$v} {
        padding-bottom: (percentage($v * 100 / $h) / 100);
    }
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.c-lazyload {
    display: block;
    overflow: hidden;
    position: relative;
    font-size: 0;
    line-height: 0;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #fff url(#{data-uri("../img/main/loading_thumb.png")}) no-repeat 50% 50%;
        background-size: 40px 36px;
        opacity: 1;
    }
    img,
    iframe {
        position: absolute !important;
        top: 0;
        left: 0;
        right: 0;
        width: initial;
        height: 100%;
        margin: 0 auto;
        animation: fadeInOpacity 0.2s ease-in;
        &.c-lazyload__image {
            border-radius: 0;
            visibility: hidden;
            @at-root .c-round-button__icon {
                .c-lazyload__image {
                    border-radius: 28px;
                }
            }
        }
    }

    iframe {
        margin: 0;
        width: 1px;
        min-width: 100%;
        *width: 100%;
    }

    &--ratio_1x1 {
        padding-bottom: 100%;
    }

    &--ratio_2x1 {
        padding-bottom: 50%;
    }

    &ed {
        display: block;
        overflow: hidden;
        position: relative;
        font-size: 0;
        line-height: 0;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 1;
            background-image: none;
        }
    }
}
