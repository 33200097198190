@import "../../common/sprites/sp_eui";
@mixin ranking-arrow($switch: up) {
    display: block;
    @if $switch == "up" {
        @include sprite-retina($sp_eui_up);
    } @else if $switch == "down" {
        @include sprite-retina($sp_eui_down);
    } @else if $switch == "same" {
        @include size(10px 2px);
        background-color: $color-gray-06;
    } @else if $switch == "new" {
        @include sprite-retina($sp_eui_new_text);
    }
}
