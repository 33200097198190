@import "../sprites/sp_components";

.c-category-tab {
    display: flex;
    width: 100%;
    background: #fafafa;
    color: #878787;
    &__primary,
    &__secondary {
        position: relative;
        display: block;
        line-height: 44px;
        font-size: 13px;
        font-weight: normal;
        letter-spacing: 0;
        a {

            height: 42px;
            position: relative;
            display: block;
            color: #878787;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            &:after {
                @include size(8px);
                @include rotate(45deg);
                @include transition(.3s);
                position: absolute;
                top: 50%;
                right: -5px;
                margin: -6px 0 0 -6px;
                border: 1px solid #333;
                opacity: 0.4;
                border-width: 0 1px 1px 0;
                content: "";
                z-index: 2;
            }
        }
        &.active {
            background-color: #fff;
            a {
                color: $color-11st-red;
                &:after {
                    @include rotate(225deg);
                    top: 62%;
                    border-color: $color-11st-red;
                    opacity: 1;
                }
            }
        }
        &.on {
            background-color: #fff;
            a {
                color: #0e0e0e;
                &:after {
                    @include rotate(45deg);
                    top: 50%;
                    border-color: #333;
                    opacity: 1;
                }
            }
        }
    }
    &__primary {
        @include size(48% 42px);
        &:after {
            @include sprite-retina($sp_components_category-tab_step);
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: -12px;
            z-index: 1;
        }
        a {
            padding-left: 16px;
            padding-right: 24px;
            &:after {
                right: 8px;
            }
        }
    }
    &__secondary {
        @include size(52% 42px);
        a {
            padding-left: 24px;
            padding-right: 28px;
            &:after {
                right: 16px;
            }
        }
    }
}
