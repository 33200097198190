@mixin ico-check ($color:#000, $left-border-length:8px, $right-border-length:16px) {
    &:after {
        content: '';
        position: relative;
        top: -4px;
        display: inline-block;
        margin-left: 10px;
        border: 1px solid $color;
        border-width: 1px 1px 0px 0px;
        width: $right-border-length;
        height: $left-border-length;
        transform: rotate(135deg);
    }
}