.c-gridtext {
    overflow: hidden;
    margin-bottom: -2px;
    width: 100%;
    background: #fff;

    &__item {
        float: left;
        box-sizing: border-box;
        margin: 0;
        width: 33.3%;
        height: 44px;
        border: 1px solid #f4f4f4;
        border-width: 0 1px 1px 0;

        &:nth-child(3n + 1) {
            clear: both;
        }

        &:nth-child(3n) {
            border-right-width: 0;
        }

        & {
            @include mq-640 {
                width: 16.666%;

                &:nth-child(3n) {
                    border-right-width: 1px;
                }

                &:nth-child(3n + 1) {
                    clear: none;
                }
            }
        }

        .c-gridtext__link {
            display: block;
            min-height: 43px;
            height: 43px;
            line-height: 43px;
            padding: 0;
            text-align: center;
            position: relative;
            width: 100%;
            color: $color-gray-03;
            font-size: 15px;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                border: 1px solid $color-gray-14;
                border-width: 0 1px 1px 0;
            }
        }

        &.on {
            .c-gridtext__link {
                background: transparent;
                color: $color-11st-red;
            }
        }

        .c-gridtext__text {
            @include text-ellipsis;

            padding: 0 2px;
            display: block;
        }
    }
}
