.c-nation {
    margin-bottom: 7px;

    dt {
        @include sr-only;
    }
    dd {
        display: flex;
    }
    .amazon {
        @include sr-only-reset;
        @include logo-svg($logo-amazon, 54px, 16px);
        display: inline-block;
        overflow: hidden;
        margin: 3px 0 -3px -1px;
        color: transparent;
    }
    .nation {
        display: inline-block;
        overflow: hidden;
        position: relative;
        height: 18px;
        padding-left: 9px;
        font-size: 13px;
        color: $color-gray-03;
        line-height: 18px;
        flex: 1;

        &:before {
            @include size(2px);
            position: absolute;
            top: 7px;
            left: 4px;
            border-radius: 2px;
            background: $color-gray-10;
            content: "";
        }
    }
}
