// 슈팅설치
@mixin retail-seller {
    .shooting-seller {
        @include retail-common("shooting_seller", 55);

        strong {
            margin-right: 4px;
        }
        em,
        .c-card-item__point {
            margin-right: 3px;
        }
    }
}
