@mixin zzim-product-svg($switch: on, $circle-border-color: $color-gray-10) {
    @if $switch== "on" {
        &:after {
            @include get-sprite-position-common("icon_heart_on", 20);
        }
    } @else if $switch== "off" {
        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &:before {
            width: 28px;
            height: 28px;
            border: 1px solid $circle-border-color;
            border-radius: 50%;
            box-sizing: border-box;
        }
        &:after {
            @include get-sprite-common("icon_heart", 20);
            margin-top: 1px;
        }
    }
}
