.c-relates {
    background: $color-gray-13;
    position: relative;
    padding: 6px 0 5px;
    margin-top: -1px;
    border-top: 1px solid $color-gray-12;
    overflow: hidden;
    &__txt {
        display: inline-block;
        padding: 0 0 0 16px;
        font-size: 13px;
        line-height: 17px;
        color: $color-gray-04;
        white-space: nowrap;
        .c-relates__emphasis {
            color: #ff8411;
            padding-right: 8px;
            font-weight: normal;
        }
        .c-relates__item {
            padding-right: 8px;
        }
        .c-relates__tag {
            @include border-radius(12px);
            background: $color-gray-12;
            color: #a0a0a0;
            display: inline-block;
            padding: 0 10px;
            font-size: 12px;
        }
    }
    &__function {
        float: right;
        display: block;
        font-size: 13px;
        padding-right: 8px;
        padding-top: 1px;
        line-height: 17px;
        .c-relates__function-txt {
            padding-right: 8px;
            white-space: nowrap;
        }
        .c-relates__button-menu {
            position: relative;
            right: 0;
            bottom: 0;
            @include size(24px 24px);
            background: transparent;
            border: 0;
            outline: none;
            text-indent: -99999px;
            &:before {
                position: absolute;
                top: 50%;
                left: 50%;
                @include size(3px 3px);
                @include border-radius(5px);
                content: "";
                margin: -2px 0 0 -2px;
                background: #000;
                box-shadow: 5px 0 0 #000, -5px 0 0 #000;
            }
        }
        .c-relates__button-link {
            display: block;
            padding: 1px 24px 1px 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:after {
                @include rotate(45deg);
                @include size(8px);
                content: "";
                position: absolute;
                top: 52%;
                right: 16px;
                margin: -5px 0 0 -5px;
                border: 1px solid #333;
                border-width: 1px 1px 0 0;
                margin-left: -8px;
            }
        }
        .c-relates__button-button {
            display: block;
            .c-relates__button-column {
                display: inline-block;
                width: 48%;
                padding-right: 2px;
                &:nth-child(2) {
                    padding-right: 0;
                }
            }
        }
        .c-relates__icon {
            @include size(74px 19px);
            display: block;
            padding-top: 3px;
            img {
                width: 100%;
                display: block;
            }
        }
        .c-relates__star {
            overflow: hidden;
            padding-right: 8px;
            margin-top: 2px;
            .c-relates__sati {
                @include size(60px 12px);
                background: url("../../img/skpui/tmp/sp_star.png") no-repeat 0 0;
                background-size: 60px 25px;
                position: relative;
                float: left;
                overflow: hidden;
                margin: 1px 5px 0 0;
                color: transparent;
                font-size: 10px;
                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 13px;
                    background: url("../../img/skpui/tmp/sp_star.png") no-repeat 0 -13px;
                    background-size: 60px 25px;
                }
                &.mj100:after {
                    width: 100%;
                }
                &.mj90:after {
                    width: 90%;
                }
                &.mj80:after {
                    width: 80%;
                }
                &.mj70:after {
                    width: 70%;
                }
                &.mj60:after {
                    width: 60%;
                }
                &.mj50:after {
                    width: 49%;
                }
                &.mj40:after {
                    width: 40%;
                }
                &.mj30:after {
                    width: 30%;
                }
                &.mj20:after {
                    width: 20%;
                }
                &.mj10:after {
                    width: 10%;
                }
            }
            .c-relates__rev {
                float: left;
                color: $color-gray-07;
                font-size: 13px;
                line-height: 15px;
            }
        }
    }
    &__link {
        display: block;
        margin: -7px 0 -8px 0;
        padding: 10px 0;
        text-align: center;
        &-text {
            padding-right: 20px;
            position: relative;
            font-size: 14px;
            &:after {
                @include rotate(133deg);
                @include size(8px);
                content: "";
                position: absolute;
                top: 40%;
                right: 2px;
                margin: -5px 0 0 -8px;
                border: 1px solid #333;
                border-width: 1px 1px 0 0;
            }
        }
    }
    &--big {
        height: 44px;
        line-height: 46px;
        margin: 0;
        padding: 0;
        font-size: 15px;
        color: #333;
        letter-spacing: 0;
        &:before {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background-color: $color-gray-12;
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    &__refresh {
        position: relative;
        padding-right: 32px;
        display: inline-block;
        &:after {
            @include sprite-retina($sp_icons_reloading);
            display: block;
            content: "";
            position: absolute;
            right: 0;
            top: 10px;
        }
    }

    &__more {
        position: relative;
        padding-right: 32px;
        display: inline-block;
        &:after {
            @include sprite-retina($sp_icons_flat_button_more);
            display: block;
            content: "";
            position: absolute;
            right: 0;
            top: 10px;
        }
    }
    &__arrow {
        position: relative;
        padding-right: 32px;
        display: inline-block;
        &:after {
            @include sprite-retina($sp_icons_flat_button_arrow);
            display: block;
            content: "";
            position: absolute;
            right: 0;
            top: 10px;
        }
    }

    button.c-relates__link {
        border: 0;
        background: #fff;
        width: 100%;
    }
    &.c-relates__gallery {
        padding-bottom: 0;
        .c-relates__txt {
            padding-left: 8px;
        }
        .c-relates__function {
            .c-relates__button-link {
                &:after {
                    top: 67%;
                }
            }
        }
    }
    .c-card__deal + & {
        border-bottom: 1px solid #d1d1d6;
    }
    &.c-relates--close {
        .c-relates__link-text:after {
            @include rotate(314deg);
            margin-top: 0;
        }
    }
    &.c-relates--refresh {
        .c-relates__link-text {
            display: inline-block;
            padding-left: 24px;
            position: relative;
            font-size: 13px;
            &:after {
                @include sprite-retina($sp_icons_arrow_refresh);
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                display: block;
                width: 24px;
                height: 24px;
                margin: -12px 0 0 0;
                border: 0;
                transform: initial;
            }
        }
    }
    &.c-relates--linkmove {
        .c-relates__link-text {
            display: inline-block;
            padding-right: 14px;
            position: relative;
            font-size: 14px;
            &:after {
                @include rotate(45deg);
                @include size(8px);
                content: "";
                position: absolute;
                top: 50%;
                right: 0;
                margin: -5px 0 0 0px;
                border: 1px solid #333;
                border-width: 1px 1px 0 0;
            }
        }
    }
    &.c-relates--border {
        margin-top: -1px;
        border-top: 0;

        &:before {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background-color: $color-gray-12;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
    &.c-relates--no-border {
        border-top: 0;
    }
}

[data-type="Rel_A_04"] {
    .c-relates__txt {
        height: 24px;
        line-height: 24px;
    }
}

[data-type="Rel_A_06"] {
    .c-relates__txt {
        height: 24px;
        line-height: 24px;
    }
}

[data-type="Rel_A_07"] {
    .c-relates__txt {
        height: 32px;
        line-height: 32px;
    }
    .c-button__medium {
        min-width: 64px;
    }
    &.c-relates__gallery {
        .c-relates__function {
            float: none;
            text-align: center;
            padding-right: 0;
            .c-button__medium {
                min-width: 100%;
            }
        }
    }
    @media screen and (min-width: 320px) and (max-width: 359px) {
        &.c-relates__gallery {
            .c-relates__function {
                .c-button__medium {
                    min-width: 100%;
                }
            }
        }
    }
}

[data-type="Rel_D_01"] {
    .c-relates__txt {
        height: 24px;
        line-height: 24px;
    }
}

[data-type="Rel_E_01"] {
    padding: 0;
}
