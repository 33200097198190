@import "../../svg/sp_live11";

@mixin alarm-product($switch: on) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    @if $switch== "on" {
        @include get-sprite-live11("ic-alarm-off");
    } @else if $switch== "off" {
        @include get-sprite-live11("ic-alarm");
    }
}
