.w-scroll,
.w-swiper {
    position: relative;
    overflow: hidden;
}

.w-scroll--css {
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}

.w-scroll-css {
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;

    .s-list {
        overflow: initial;
    }
}

.w-swiper {
    &__item {
        img {
            width: 100%;
            display: block;
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        background-color: transparent;
        background-image: none;
        border: 0;
        text-indent: -99999px;
        overflow: hidden;
    }

    .swiper-button-prev:before {
        @include size(18px 18px);
        @include rotate(45deg);

        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -9px 0 0 -5px;
        border: 2px solid rgba(59, 59, 59, 0.5);
        border-width: 0 0 2px 2px;
    }

    .swiper-button-next:before {
        @include size(18px 18px);
        @include rotate(45deg);

        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -9px 0 0 -15px;
        border: 2px solid rgba(59, 59, 59, 0.5);
        border-width: 2px 2px 0 0;
    }

    .swiper-button-prev {
        left: 0;
    }

    .swiper-button-next {
        right: 0;
    }
}

.s-list {
    overflow: hidden;
    display: table;
    padding: 4px 12px;

    li {
        display: table-cell;
        padding: 4px;
        vertical-align: top;
    }
}

.w-scroll {
    [data-type="ProductScroll_Basic"] {
        margin-bottom: 16px;
    }

    [data-type="GridScroll_IconText"] {
        margin-left: 0;
    }
}
