@import "../sprites/sp_icon";
@import "../../pui/sprites/sp_icons";

.c-addition {
    position: relative;
    border-top: 1px solid #f4f4f4;
    background-color: $color-gray-14;
    font-size: 13px;
    line-height: 16px;
    color: $color-gray-03;

    .c-card__deal + & {
        margin-top: -1px;
    }

    & + & {
        margin-top: -1px;
    }

    > div,
    > ul,
    > dl,
    > a,
    .c-addition-link {
        display: block;
        position: relative;
        padding: 10px 16px;
    }

    & &-link {
        display: flex;
    }

    &-desc1 {
        height: 20px;
        dt {
            float: left;
            margin-right: 4px;

            > span {
                display: block;
                padding: 1px 8px;
                border: 1px solid #eaeaea;
                border-radius: 11px;
                background-color: #fff;
                font-size: 11px;
            }

            > strong {
                display: block;
                padding: 1px 8px;
                border: 1px solid #eaeaea;
                border-radius: 11px;
                background-color: #fff;
                font-size: 11px;
            }
        }

        dd {
            overflow: hidden;
            padding: 2px 10px 2px 0;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    &-desc2 {
        max-height: 40px;
        overflow: hidden;

        li {
            display: inline-block;
            max-width: 100%;
            margin: 0 19px 0 -11px;
            padding: 2px 1px 2px 0;
            white-space: nowrap;
            vertical-align: top;
            overflow: hidden;
            text-overflow: ellipsis;

            &:before {
                display: inline-block;
                width: 1px;
                height: 10px;
                margin: -1px 10px 1px 0;
                background-color: #eaeaea;
                vertical-align: middle;
                content: "";
            }
        }

        &:before {
            position: absolute;
            left: 5px;
            top: 10px;
            bottom: 10px;
            width: 1px;
            background-color: #fafafa;
            content: "";
        }
    }

    &-coupon {
        overflow: hidden;
        display: flex;
        position: relative;
        width: 50%;
        height: 20px;

        &__rate {
            display: flex;
            overflow: hidden;
            position: relative;
            min-width: 32px;
            height: 20px;
            margin-right: 6px;
            padding: 2px 12px 2px 5px;
            background: $color-gray-14;
            font-size: 11px;
            color: $color-gray-02;
            line-height: 18px;
            align-items: baseline;

            &:before {
                @include size(8px 20px);
                position: absolute;
                top: 0;
                right: 0;
                background: $color-11st-gradient-vertical;
                content: "";
            }
            &:after {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                border: 1px solid rgba($color: $color-gray-01, $alpha: 0.1);
                border-radius: 2px 0px 0 2px;
                content: "";
            }
            em {
                font-weight: bold;
                white-space: nowrap;

                &:before,
                &:after {
                    @include size(5px 6px);
                    position: absolute;
                    z-index: 1;
                    right: 5px;
                    border: 1px solid rgba($color: $color-gray-01, $alpha: 0.1);
                    border-radius: 100%;
                    background: $color-gray-14;
                    background-clip: padding-box;
                    content: "";
                }
                &:before {
                    top: -17.5%;
                }
                &:after {
                    bottom: -17.5%;
                }
            }
            .c-addition-coupon__normal {
                font-weight: normal;
            }
        }

        &__text {
            display: block;
            position: relative;
            padding: 2px 13px 2px 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            line-height: 18px;

            &:after {
                @include ico-arrow-right(7px, $color-gray-03);
                position: absolute;
                top: 50%;
                right: 2px;
                margin-top: -4px;
            }
            strong {
                font-weight: normal;
                color: $color-11st-red;
            }
        }

        &--single {
            width: initial;

            .c-addition-coupon__text {
                flex: 1;
            }
        }

        &--angle {
            display: flex;
            flex: 1 1 0;
            .c-addition-coupon__rate {
                flex: 1 0 auto;
                flex-grow: initial;
            }

            &:nth-child(2) {
                margin-left: 10px;
                justify-content: flex-end;
            }
        }
    }

    &-link__text {
        position: absolute;
        right: 16px;
        top: 10px;
        height: 20px;
        padding: 2px 10px 2px 0;
        text-align: right;

        &:after {
            @include rotate(45deg);
            @include size(7px);

            content: "";
            position: absolute;
            top: 50%;
            right: 1px;
            margin-top: -4px;
            border: solid $color-gray-03;
            border-width: 1px 1px 0 0;
        }
        &.c-addition-link--store {
            line-height: 16px;
            background-position: 0 -2px;
        }
    }

    &-tag {
        &__list {
            overflow: hidden;
            height: 26px;
            line-height: 26px;
        }

        &__item {
            display: inline-block;
            max-width: 100%;
            margin-right: 0;
            padding: 3px 8px;
            font-size: $font-size-caption1;
            line-height: 16px;
            white-space: nowrap;
            vertical-align: middle;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &-review {
        position: relative;
        min-height: 40px;
        padding: 2px 0 2px 48px;

        dt {
            position: absolute;
            left: 0;
            top: 0;
            width: 40px;
            height: 40px;
            padding: 11px 0;
            border: 1px solid #e7e7e7;
            border-radius: 50%;
            background-color: #fff;
            font-size: 11px;
            text-align: center;
        }

        dd {
            padding: 1px 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .c-card__quotation {
        position: relative;
        overflow: hidden;
        display: -webkit-box;
        padding: 0 0 0 27px;
        font-size: $font-size-body1;
        color: $color-gray-04;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 1.5;
        &:before {
            @include review-quote();
            left: 8px;
            top: -1px;
        }
    }

    &-link--store {
        display: block;
        height: 20px;
        line-height: 22px;
        padding-left: 24px;
        background: url("/MW/img/shockingdeal/ic_store.png") no-repeat 0 0;
        background-size: 20px 20px;
    }

    &-link--text {
        color: #999;
    }

    &-link--double {
        display: flex !important;
        padding-right: 168px !important;
        .c-addition-coupon {
            position: relative;
            width: initial;
            padding-right: 10px;

            &__text {
                position: relative;
                padding-right: 10px;
                flex: 1;

                &:after {
                    @include size(7px);
                    @include rotate(45deg);
                    display: block;
                    content: "";
                    position: absolute;
                    top: 50%;
                    right: 2px;
                    margin-top: -4px;
                    border: solid $color-gray-03;
                    border-width: 1px 1px 0 0;
                }
            }
            .c-addition-link__text {
                position: relative;
                top: initial;
                min-width: 64px;
                max-width: 105px;
                padding-left: 4px;
                line-height: 18px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            &:nth-of-type(2) {
                position: absolute;
                right: 0px;
                padding-right: 0;
                .c-addition-coupon__rate {
                    margin-right: 16px;
                }
            }
        }
        .c-addition-link {
            position: static;
            padding: 0;
        }
    }

    &-link--single {
        .c-addition-coupon {
            width: initial;
        }
    }

    .w-scroll--css,
    .w-scroll {
        padding: 0;

        .prd-snippet {
            display: block;
            position: relative;
            width: 180px;
            height: 40px;
            padding: 3px 10px 3px 50px;
            background-color: #fff;

            &__thumb {
                position: absolute;
                left: 0;
                top: 0;
                width: 40px;
                height: 40px;
            }

            &__name {
                display: block;
                padding: 1px 0;
                color: #666;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            &__price {
                display: block;
                color: #333;
            }

            &:after {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                border: 1px solid rgba(0, 0, 0, 0.1);
                content: "";
            }
        }

        .more {
            display: block;
            position: relative;
            width: 40px;
            height: 40px;
            line-height: 16px;
            text-align: center;

            &:before {
                @include sprite-retina($sp_icon_addition_arrow);

                display: block;
                margin: 0 auto;
                content: "";
            }
        }
    }
}
[data-log-actionid-label="exhibition"] {
    .c-addition-link--store {
        background: none;
    }
}
