
// 공통 믹스인 사용하는 요소는 스토어 찜 버튼 밖에 없음.
// 나머지는 다 로드샵에서만 사용되는 믹스인 모음.
// 별도의 업데이트가 없는 경우 수정하지 않고 현재 상태 유지한다. (2020-04-09)
@import "../../roadshop/sprites/sp_icon";
@import "../../pui/sprites/sp_btn_store";


@mixin button-heart--rounded {
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.7);
    @include sprite-retina($sp_card_ico_like);

    &.on {
        @include sprite-retina($sp_card_ico_like_fill);
    }
}

@mixin c-like-button--44 {
    width: 44px;
    height: 44px;
    border: 0;
    background-color: transparent;

    @include sprite-retina($sp_icon_ic_heart_off);

    &.active {
        @include sprite-retina($sp_icon_ic_heart_on);
    }
}

@mixin pagination-roadshop {
    .c-pagination__indicator {
        span {
            @include size(5px 6px);
            background-color: #e8e6ea;
            transform: skewX(-35deg);
            border: 0;
            border-radius: 0;
            opacity: 1;

            &.on {
                background-color: #34d6b1;
            }
        }

        span + span {
            margin-left: 9px;
        }
    }
}

@mixin c-store-like-button {
    @include size(24px);
    border: 0;
    background-color: transparent;
    &:before {
        @include zzim-store(off);
    }

    &.active {
        &:before {
            @include zzim-store(on);
        }
    }
}
