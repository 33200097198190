.c-tab {
    background: #fff;

    &__list {
        display: table;
        width: 100%;
    }

    &__item {
        display: table-cell;
        width: 50%;

        &.on {
            .c-tab__link {
                z-index: 5;
                border: 1px solid $color-11st-red;
                font-weight: bold;
                color: $color-11st-red;
            }
        }
    }

    &__link {
        display: block;
        position: relative;
        height: 44px;
        line-height: 44px;
        text-align: center;
        border: 1px solid $color-gray-10;
        margin-right: -1px;
        background: $color-gray-14;
        font-size: 15px;
        color: #111;
        box-sizing: border-box;
    }

    &--col3 {
        .c-tab__item {
            width: 33.3%;
        }
    }

    &--col4 {
        .c-tab__item {
            width: 25%;
        }
    }

    &--redbg {
        .c-tab__item {
            &:last-child {
                border-right: 0;
            }

            .c-tab__link {
                position: relative;
                height: 44px;
                border-color: rgba($color: #ccc, $alpha: 0.2);
                background: $color-gray-13;
                line-height: 44px;
                font-size: 14px;

                .c-tab__icon-new {
                    @include size(4px);

                    border-radius: 2px;
                    overflow: hidden;
                    position: absolute;
                    top: 6px;
                    margin-left: 4px;
                    background: $color-11st-red;
                    color: transparent;
                    font-size: 2px;
                }
            }

            &.on {
                .c-tab__link {
                    background: $color-11st-red;
                    border-color: $color-11st-red;
                    color: #fff;
                }
            }
        }
    }
}

[data-type="Tabs_ImgText_Col3_Nobg"] {
    background: transparent;

    .c-tab__link {
        background: transparent;
        border: none;
    }

    .c-tab__item {
        position: relative;

        &:after {
            @include size(1px 16px);

            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: -8px;
            background: #d1d1d6;
        }

        &:last-child {
            &:after {
                display: none;
            }
            .c-tab__link {
                border-right: 0;
            }
        }
    }

    .c-tab__link {
        &-icon {
            font-size: 14px;
            color: #666;

            img {
                @include size(24px);

                margin-top: -4px;
                margin-right: 4px;
                vertical-align: middle;
            }
        }
    }
}
