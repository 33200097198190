@import "../sprites/sp_share_button";

.c-popup-share {
    @include size(296px 192px);

    background: #fff;
    padding: 0;

    &__title {
        @include vertical-middle(48px);

        padding: 0;
        margin: 0;
        font-size: 17px;
        font-weight: normal;
        background: #fff;
        border-bottom: 1px solid #f4f4f4;
        text-align: center;
        color: #333;
    }

    &__list {
        display: table;
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;

        li {
            display: inline-table;
            width: 33.3%;
            height: 72px;
        }
    }

    &__service {
        display: block;
        padding: 14px 0;
        text-align: center;
        font-size: 12px;
        color: #666;

        &:before {
            content: '';
            display: block;
            margin: 0 auto 8px auto;
        }

        &.kakao:before {
            @include sprite-retina($sp_share_button_kakao);
        }

        &.line:before {
            @include sprite-retina($sp_share_button_line);
        }

        &.facebook:before {
            @include sprite-retina($sp_share_button_facebook);
        }
    }

    &__close {
        @include size(100% 48px);

        display: block;
        background: #fafafa;
        border: 0;
        border-top: 1px solid #d1d1d6;
        color: #333;
        font-size: 17px;
    }
}
