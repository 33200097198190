@import "../../skpui/sprites/sp_card";

@mixin inline-definition($font-size) {
    font-size: $font-size + px;
    line-height: $font-size + 4 + px;
    letter-spacing: 0;
    color: #999;

    dt {
        display: inline-block;

        &.star {
            margin: -1px 0 1px;
            vertical-align: middle;

            &:before {
                @include sprite-retina($sp_card_ico_rate_star);
                display: block;
                content: '';
            }
        }
    }

    dd {
        display: inline-block;
        vertical-align: top;

        em {
            color: #535353;
        }
    }

    &.separated dd + dt:before {
        display: inline-block;
        height: 12px;
        margin: -1px 6px 1px 4px;
        border-left: 1px solid #e3e3e8;
        vertical-align: middle;
        content: '';
    }
}
