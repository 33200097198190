.c-nav {
    background-color: $color-gray-14;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    overflow: hidden;
    position: relative;

    &__wrap {
        margin-right: 45px;

        .c-nav__list {
            display: table;
            position: relative;
            z-index: 2;
            width: 100%;
            padding: 0 6px;

            &.s-list {
                margin-left: 0;
            }
        }

        .c-nav__item {
            display: table-cell;
            text-align: center;
            white-space: nowrap;
            padding: 0;
            font-size: 15px;

            a {
                display: block;
                position: relative;
                padding: 2px 10px;
                line-height: 40px;
                color: $color-gray-03;
                font-weight: bold;

                &:after {
                    @include size(100% 2px);

                    position: absolute;
                    left: 0;
                    bottom: 0;
                    content: "";
                }
            }

            &.on {
                a {
                    padding: 2px 0;
                    color: $color-11st-red;

                    &:after {
                        background-color: $color-11st-red;
                    }
                }

                &:first-child {
                    a {
                        padding-left: 10px;
                    }
                }
                &:last-child {
                    a {
                        padding-right: 10px;
                    }
                }
            }

            &__icon {
                @include size(32px);

                position: relative;
                display: block;
                margin: 0 auto;

                img {
                    display: block;
                    width: 100%;
                }
            }

            &__title {
                display: block;
                line-height: 18px;
                font-size: 13px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .c-nav__button-toggle {
            @include size(32px);

            position: absolute;
            top: 5px;
            right: 8px;
            z-index: 3;
            border: 1px solid rgba(187, 187, 187, 0.8);
            border-radius: 4px;
            background-color: rgba(255, 255, 255, 0.9);
            color: transparent;
            outline: none;

            &:before {
                @include size(8px);
                @include rotate(45deg);
                @include transition(0.3s);

                position: absolute;
                top: 50%;
                left: 50%;
                margin: -7px 0 0 -7px;
                border: 2px solid #333;
                border-width: 0 2px 2px 0;
                transform-origin: 70% 70%;
                transition: 0.3s;
                content: "";
            }

            &:after {
                @include size(14px 43px);

                position: absolute;
                left: -15px;
                top: 0;
                content: "";
                background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
                background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
            }
        }

        .c-nav__button-close {
            &:before {
                @include rotate(225deg);
            }
        }
    }

    &--spread {
        .c-nav__wrap {
            margin-right: 0;

            .c-nav__item {
                a {
                    font-weight: normal;
                }
            }
        }
    }

    &--sub {
        // height: 44px;

        .c-nav__wrap {
            margin-right: 0;
            background: #fafafa;

            .c-nav__list {
                width: initial;
                max-width: initial;
            }

            .c-nav__item {
                a {
                    font-weight: normal;
                    font-size: 14px;

                    &:after {
                        display: none;
                    }
                }

                &.on a span {
                    @include border-radius(14px);

                    display: inline-block;
                    line-height: 18px;
                    padding: 4px 10px;
                    border: 1px solid $color-11st-red;
                    background: #fff;
                }
            }
        }
    }

    &__filter {
        border-bottom: 1px solid #e4e4e4;

        &-title {
            @include vertical-middle(49px);

            padding-left: 16px;
            font-size: 15px;
        }

        &-keyword {
            position: relative;
            font-size: 18px;
            color: #333;

            &.on {
                color: $color-11st-red;
                padding-left: 22px;

                &:before {
                    @include size(9px);

                    @include rotate(-45deg);

                    @include transition(0.3s);

                    position: absolute;
                    top: 70%;
                    left: 8px;
                    margin: -10px 0 0 -7px;
                    border: 1px solid #999;
                    border-width: 0 1px 1px 0;
                    content: "";
                }
            }

            &:nth-of-type(2) {
                padding-left: 22px;

                &:before {
                    @include size(9px);

                    @include rotate(-45deg);

                    @include transition(0.3s);

                    position: absolute;
                    top: 70%;
                    left: 10px;
                    margin: -10px 0 0 -7px;
                    border: 1px solid #999;
                    border-width: 0 1px 1px 0;
                    content: "";
                }
            }

            &:nth-of-type(3).on {
                padding-left: 22px;

                &:before {
                    @include size(9px);

                    @include rotate(-45deg);

                    @include transition(0.3s);

                    position: absolute;
                    top: 70%;
                    left: 10px;
                    margin: -10px 0 0 -7px;
                    border: 1px solid #999;
                    border-width: 0 1px 1px 0;
                    content: "";
                }
            }
        }

        .c-nav__button-toggle {
            @include size(45px 49px);
            top: 0;
            right: 0;
            border: 0;

            &:before {
                @include size(11px);
                margin: -10px 0 0 -7px;
                border-width: 0 1px 1px 0;
            }
        }
    }
}
