[data-type="ProductGrid_Ranking_Logo"] {
    @include grid(2, 2, true);

    .c-card.c-card__gallery {
        .c-card__info {
            .c-card__benefit {
                height: 48px;
                .benefit {
                    display: block;
                }
            }
        }
    }
    [data-type="Rel_A_04_01"] {
        padding: 0;
        border-bottom: 1px solid #f4f4f4;

        .c-relates__function {
            float: none;
            padding-left: 8px;
            .c-relates__button-link {
                &:after {
                    top: 50% !important;
                    border-color: #999;
                }
            }
        }
        &.c-relates__gallery {
            .c-relates__function {
                .c-relates__button-link {
                    padding: 6px 0 6px;
                    .c-relates__icon {
                        @include size(64px 24px);
                        padding-top: 0;
                    }
                }
            }
        }
    }
}
