@import "../../pui/sprites/sp_mart";

.w-prd__count {
    position: relative;
    min-height: 32px;
    padding: 0 16px 0 16px;
    box-sizing: initial;

    .c-prd__count {
        position: relative;
        margin-right: 40px;

        &--qty {
            display: block;
            overflow: hidden;
            margin: 0 32px;
            font-size: 16px;
            color: $color-gray-03;
            text-align: center;
            height: 32px;
            border: 1px solid $color-gray-11;
            border-width: 1px 0 1px 0;
            background: $color-gray-14;
            line-height: 32px;
        }

        button {
            position: absolute;
            top: 0;
            overflow: hidden;
            color: transparent;
            width: 32px;
            height: 32px;
            border: 1px solid $color-gray-11;
            background-color: $color-gray-14;

            &:focus:not(:focus-visible) {
                outline: 0;
            }
            // MPINNER-10049 반영시 삭제 예정
            &:focus {
                outline: 0;
            }

            &::before,
            &::after {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                height: 3px;
                margin-left: -12px;
            }
        }

        &--remove {
            left: 0;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;

            &::before {
                width: 24px;
                margin: -1px 0 0 -6px;
                background-color: $color-gray-06;
                transform: scale(0.5);
            }
        }

        &--add {
            right: 0;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;

            &::before {
                width: 24px;
                margin: -1px 0 0 -5px;
                background-color: $color-gray-06;
                transform: scale(0.5);
            }

            &::after {
                width: 24px;
                margin: -1px 0 0 -5px;
                background-color: $color-gray-06;
                transform: scale(0.5) rotate(90deg);
            }
        }
    }

    .c-prd__count--save {
        position: absolute;
        right: 16px;
        top: 0;
        color: transparent;
        display: block;
        overflow: hidden;
        width: 32px;
        height: 32px;
        border: 1px solid $color-gray-11;
        border-radius: 4px;
        background-color: $color-gray-14;

        &:focus:not(:focus-visible) {
            outline: 0;
        }
        // MPINNER-10049 반영시 삭제 예정
        &:focus {
            outline: 0;
        }

        &::before {
            content: "";
            position: absolute;
            left: 2px;
            top: 3px;
            display: block;
            margin: 0 auto;
            @include sprite-retina($sp_mart_cart);
        }
    }

    &--link {
        display: block;
        border: 1px solid $color-gray-11;
        border-radius: 4px;
        font-size: 13px;
        color: $color-gray-03;
        position: relative;
        padding-left: 12px;
        line-height: 32px;
        height: 32px;

        &::after {
            position: absolute;
            right: 16px;
            top: 11px;
            @include ico-arrow-right(7px, $color-gray-07, 1px);
        }
    }
}
