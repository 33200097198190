@import "../../common/mixins";

// Go Previous Page Button in Homeshopping Category Page
.bt_prev {
    @include size(39px 43px);
    display: block;
    position: absolute;
    top: 6px;
    overflow: hidden;
    margin-left: 10px;
    border: none;
    background: url("/MW/img/common/gnb_fv.png") no-repeat -76px 4px;
    background-size: 325px 36px;
    color: transparent;
    z-index: 1;
}