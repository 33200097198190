@import "../../common/partial/icon";
@import "../../roadshop/sprites/sp_icon";

.b-card--recommand {
    position: relative;

    .c-card__thumb {
        width: 100% !important;
        height: auto !important;
    }

    .c-prd-info {
        padding-top: 13px;
    }

    .c-like-prd {
        @include button-heart--rounded;
        position: absolute;
        right: 4px;
        top: -30px;
        margin-top: 100%;
    }
}
.l-grid__col--6 {
    .b-card--recommand {
        .c-prd-info {
            height: 174px;
        }
    }
}
