@import "../../common/sprites/sp_eui";

.c-time {
    float: left;
    height: 18px;
    margin: 0 4px 4px 0;
    padding: 3px 4px 1px;
    min-width: 65px;
    border-color: $color-amazon-bluegreen;
    background-color: $color-amazon-bluegreen;
    font-size: 11px;
    color: $color-gray-14;
    line-height: 14px;
    white-space: nowrap;
    box-sizing: border-box;

    &:before {
        @include sprite-retina($sp_eui_timer);
        display: inline-block;
        margin: -1px 2px 1px 0;
        vertical-align: middle;
        content: "";
    }
    time {
        font-family: arial;
    }
    &--gradient-red {
        font-weight: 700;
        background: linear-gradient(to right, #ff4466 0%, $color-11st-gradient-pink 100%);
    }
    &-flag {
        dt {
            @include sr-only;
        }
    }
}
