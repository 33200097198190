.c-chips {
    color: $color-gray-03;
    background: $color-gray-14;

    @include border-radius(16px);

    height: 32px;
    line-height: 32px;
    padding: 0 12px;
    font-size: 14px;
    display: inline-block;

    &__button-delete {
        @include size(18px 18px);

        display: inline-block;
        overflow: hidden;
        position: relative;
        background: transparent;
        border: 0;
        color: transparent;
        vertical-align: middle;
        margin-top: -2px;

        &:before,
        &:after {
            background-color: $color-gray-03;
            position: absolute;
            top: 50%;
            left: 56%;
            transform: rotate(45deg);
            content: "";
        }

        &:before {
            @include size(17px 1px);

            margin: -1px 0 0 -9px;
        }

        &:after {
            @include size(1px 17px);

            margin: -9px 0 0 -1px;
        }
    }

    &.c-chips__active {
        color: $color-gray-14;
        background-color: #5676da;

        .c-chips__button-delete {
            &:before,
            &:after {
                background-color: $color-gray-14;
            }
        }
    }

    &.c-chips__inactive {
        color: $color-gray-14;
        background-color: $color-gray-08;

        .c-chips__button-delete {
            &:before,
            &:after {
                background-color: $color-gray-14;
            }
        }
    }

    &.c-chips--small {
        height: 24px;
        line-height: 24px;
        padding: 0 8px;
    }
}
