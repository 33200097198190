.c-cap {
    position: relative;
    height: 40px;
    line-height: 40px;
    background: $color-gray-14;

    &__icon {
        @include box-sizing(border-box);

        display: block;
        padding: 8px 0;
        float: left;
        width: 80px;

        img {
            @include size(80px 24px);

            display: block;
        }
    }

    &__txt {
        font-size: 14px;
        color: $color-gray-04;
    }

    &__rank {
        position: absolute;
        top: 5px;
        left: 0;
        font-size: 14px;
        line-height: 15px;
        color: $color-gray-02;

        &-rising {
            position: relative;
            display: inline-block;
            top: -2px;
            font-size: 11px;
            color: $color-11st-red;
            padding-left: 10px;
            letter-spacing: 0;

            &:before {
                content: "";
                position: absolute;
                left: 2px;
                top: 4px;
                display: block;
                width: 0;
                height: 0;
                border-left: 3px solid transparent;
                border-right: 3px solid transparent;
                border-bottom: 5px solid $color-11st-red;
            }
        }

        &-fall {
            position: relative;
            display: inline-block;
            top: -1px;
            font-size: 11px;
            color: #5676da;
            padding-left: 10px;
            letter-spacing: 0;
            &:before {
                content: "";
                position: absolute;
                left: 2px;
                top: 5px;
                display: block;
                width: 0;
                height: 0;
                border-left: 3px solid transparent;
                border-right: 3px solid transparent;
                border-top: 5px solid #5676da;
            }
        }

        &-new {
            position: absolute;
            top: -6px;
            left: 2px;
            font-size: 11px;
            color: $color-11st-red;
            font-weight: 500;
        }
    }

    &__skd {
        @include size(36px 24px);

        position: absolute;
        top: 0;
        right: 0;
        display: block;
        background: url("/MW/img/shocking/logo_skd.png") no-repeat 50% 50%;
        background-size: 35px 14px;
        color: transparent;
        font-size: 10px;
    }

    &__img {
        display: block;
        padding: 4px 0;

        img {
            display: block;
        }
    }

    &.c-cap__link {
        display: block;

        &:after {
            @include rotate(45deg);

            @include size(9px);

            content: "";
            position: absolute;
            top: 50%;
            right: 5px;
            margin: -5px 0 0 -5px;
            border: 1px solid #333;
            border-width: 1px 1px 0 0;
            margin-left: -8px;
        }
    }

    &.c-cap__gallery {
        height: 30px;
        line-height: 26px;
    }

    &--homeshopping {
        margin: -16px -16px 0;
        border-top: 1px solid #e4e4e4;

        .c-cap__txt {
            position: relative;
            display: block;
            padding: 0 16px;
            height: 28px;
            line-height: 28px;
            background: #eef1ff;
            color: #606c8f;
            font-size: 11px;

            &:before {
                @include size(0);

                content: "";
                display: block;
                position: absolute;
                z-index: 1;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 8px solid #eef1ff;
                left: 20px;
                bottom: -8px;
            }
        }
    }
}

[data-type="Cap_B_01"] {
    .c-cap__rank {
        left: 0;
    }

    .c-cap__skd {
        right: 0;
    }
}

.c-item-cap {
    overflow: hidden;
    height: 44px;
    padding: 10px 0;

    &__rank {
        display: inline-block;
        min-width: 24px;
        max-width: 30px;
        height: 24px;
        padding: 0 4px;
        border: 1px solid rgba($color: $color-11st-red, $alpha: 0.1);
        background: rgba($color: $color-11st-red, $alpha: 0.04);
        font-size: 12px;
        color: $color-11st-red;
        line-height: 24px;
        text-align: center;
        white-space: nowrap;

        &.c-item-cap__rank--highlight {
            background: rgba($color: $color-11st-red, $alpha: 0.8);
            color: $color-gray-14;
        }
    }
    &__addition {
        display: block;
        float: right;
        font-size: 13px;
        line-height: 24px;
    }
    &__text {
        display: inline-block;
        font-size: 12px;
    }
    &__status {
        display: inline-block;
        font-size: 13px;

        &:before {
            display: inline-block;
            content: "";
        }
        &--up {
            color: $color-11st-red;

            &:before {
                @include ranking-arrow(up);
                display: inline-block;
                margin-right: 2px;
            }
        }
        &--down {
            color: $color-11st-blue;

            &:before {
                @include ranking-arrow(down);
                display: inline-block;
                margin-right: 2px;
            }
        }
        &--same {
            &:before {
                @include size(6px 2px);
                margin-top: -2px;
                background: $color-gray-09;
                vertical-align: middle;
            }
        }
        &--new {
            font-size: 10px;
            font-weight: bold;
            color: $color-11st-red;
        }
    }
}
