@import "../sprites/sp_components";
@import "../../common/sprites/sp_eui";

.c-iconinfo {
    @include size(24px);

    position: relative;
    display: inline-block;
    border: 0;
    padding: 0;
    background: transparent;
    text-indent: -9999px;
    vertical-align: middle;
    outline: none;

    &:after {
        @include sprite-retina($sp_components_infoa_01);

        position: absolute;
        content: '';
        display: block;
        left: 0;
        top: 0;
    }

    &--help {
        &:after {
            @include sprite-retina($sp_eui_info);

            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__help {
        &:after {
            @include sprite-retina($sp_eui_info);

            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__new {
        @include size(12px);

        margin-top: -2px;
        margin-left: 4px;

        &:after {
            @include sprite-retina($sp_components_badge_new);
        }
    }
}
