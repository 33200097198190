@charset "UTF-8";

@import "mixin_mediaquery";

// carrier 하단 라인 컬러
$carrierBorderColor: #d1d1d6;

// block 하단 라인 컬러
$blockBorderColor: #f4f4f4;

// 간격 타입
$step: (
    "step0": 0,
    "step1": 8px,
    "step2": 16px,
);

// Last column alignment
$global-right: left;

/**
 * Grid Layout
 */

// Extend the accurate placeholder for media query
// @dependency _mixin_mediaquery.scss
//
// Issue:
// Sass에서 mediaquery 사용시 extend에 적용되지 않음.
// placeholder로 미리 extend를 세팅해서 관련 이슈를 해결
// www.sitepoint.com/cross-media-query-extend-sass/
//
// Usage:
// 선언 방식:
// %clearFix {...} => @include placeholder(clearFix) {...}
//
// 호출 방식:
// @extend %clearFix; => @include _(clearFix);
@include placeholder(clearFix) {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

// grid container (ex: table)
.l-grid {
    margin-top: 10px;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    background-color: $color-gray-14;

    #cts &:nth-child(1) {
        margin-top: 0;
    }

    // foundation grid 추가
    @include foundation-grid;

    // .l-grid + .l-grid--adjoin: 상단 간격이 필요 없을 경우
    & + &--adjoin {
        margin-top: 0;
    }

    &--gapless + & {
        margin-top: 0;
    }

    &--initial {
        margin-top: initial;
        padding: initial;
        background-color: $color-gray-11;
    }

    // .l-grid--border: 하단 라인
    &--border {
        padding-bottom: 0;

        &:after {
            position: absolute;
            content: "";
            left: 0;
            right: 0;
            bottom: 0;
            border-bottom: 0;
            z-index: 1;
        }
    }

    // .l-gird--nobg: 배경색 없음
    &--nobg {
        background-color: transparent !important;
        > .l-grid__row {
            background-color: transparent;
        }
    }

    // .l-grid--visible: grid 내 레이어 팝업 노출 용
    &--visible {
        overflow: visible;
    }
}

// grid row (ex: tr)
.l-grid__row {
    position: relative;
    background-color: #fff;

    @include _(clearFix);
}

// grid col (ex: td)
.l-grid__col {
    box-sizing: border-box;

    @for $i from 1 through $grid-column-count {
        // .l-grid__col--*: width 설정
        &--#{$i} {
            @include grid-column($i);
        }
    }
}

.l-grid__row--border {
    .l-grid__col {
        &:after {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            border-bottom: 1px solid $blockBorderColor;
            content: "";
        }
    }
    &:nth-last-child(1) {
        margin-bottom: -1px;
    }
}

.l-grid__row--step0 {
    .l-grid__col {
        position: relative;
    }
    &.l-grid__row--border {
        .l-grid__col {
            padding: 0 0 1px;
        }
    }
}

.l-grid__row--step1 {
    padding: 4px;

    .l-grid__col {
        position: relative;
        padding: 4px;

        .w-scroll {
            margin: 0 -8px;
        }
    }

    &.l-grid__row--border {
        padding: 0 4px;

        .l-grid__col {
            padding: 8px 4px 9px;

            &:after {
                left: -4px;
                right: -4px;
            }
        }
    }
}

.l-grid__row--step2 {
    padding: 8px;

    .l-grid__col {
        position: relative;
        padding: 8px;

        .w-scroll {
            margin: 0 -16px;
        }
    }

    &.l-grid__row--border {
        padding: 0 8px;

        .l-grid__col {
            padding: 16px 8px 17px;

            &:after {
                left: -8px;
                right: -8px;
            }
        }
    }
}

.l-grid__row {
    .l-grid__col--12 {
        float: none;

        &.medium-6 {
            @media screen and (min-width: 640px) {
                & {
                    float: left;

                    &:nth-child(odd) {
                        clear: left;
                        padding-right: 4px;
                    }

                    &:nth-child(even) {
                        padding-left: 4px;
                    }
                }
            }
        }
    }
    // data-type, data-ui-type 속성이 없는 경우 z-index 초기화
    &:nth-child(1):not([data-type]) {
        position: relative;
        z-index: initial;
    }
}

// 빈 그리드
.l-grid:empty {
    display: none;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.l-grid__row:empty {
    display: none;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

#debug:target {
    .l-grid {
        padding: 8px;
        border: 2px solid #00f;
        background-color: #fff;
    }

    .l-grid + .l-grid {
        margin-top: 50px;
    }

    .l-grid__row[data-type] {
        border: 2px solid $color-11st-red;

        &:before {
            position: absolute;
            right: 0;
            top: 0;
            padding: 2px 5px;
            background-color: $color-11st-red;
            color: #fff;
            z-index: 10;
            content: attr(data-type);
        }
    }
}
