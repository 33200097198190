@import "../../pui/sprites/sp_icons";
@import "../../common/sprites/sp_eui";

.c-button {
    @include border-radius(4px);

    display: block;
    height: 48px;
    padding: 0 8px;
    line-height: 48px;
    text-align: center;
    border: 0;
    font-size: 17px;

    &.c-button__medium {
        height: 32px;
        line-height: 32px;
        font-size: 15px;
    }

    &.c-button__small {
        height: 24px;
        line-height: 24px;
        font-size: 14px;
    }

    &.c-button--full {
        width: 100%;
    }
}

.c-button__primary {
    @extend .c-button;

    background: $color-11st-red;
    color: $color-gray-14;
}

.c-button__secondary {
    @extend .c-button;

    background: #444;
    color: $color-gray-14;
}

.c-button__alert {
    @extend .c-button;

    background: $color-gray-14;
    color: $color-11st-red;
    border: 1px solid $color-11st-red;
}

.c-button__normal {
    @extend .c-button;

    background: $color-gray-14;
    color: $color-gray-03;
    border: 1px solid $color-gray-09;
}

.c-button__dimned {
    @extend .c-button;

    background: $color-gray-13;
    color: $color-gray-09;
    border: 1px solid rgba(204, 204, 204, 0.2);
}

.c-button__fab {
    @extend .c-button;

    @include size(56px 56px);

    @include border-radius(28px);

    min-width: inherit;
    background-color: $color-11st-red;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);

    .c-button__icon {
        @include size(24px 24px);

        position: relative;
        display: inline-block;
        line-height: 24px;
        text-indent: -999999px;

        &:before,
        &:after {
            @include rotate(0deg);

            position: absolute;
            top: 50%;
            left: 50%;
            background: $color-gray-14;
            content: "";
        }

        &:before {
            @include size(24px 1px);

            margin: -1px 0 0 -12px;
        }

        &:after {
            @include size(1px 24px);

            margin: -12px 0 0 -1px;
        }
    }

    &[aria-pressed="true"] .c-button__icon {
        @include rotate(45deg);
    }
}

.c-button--round {
    @include size(168px 32px);

    position: relative;
    margin: 0 auto;
    padding-right: 24px;
    background: #fff;
    border-radius: 20px;
    border: 1px solid rgba(86, 118, 218, 0.8);
    font-size: 15px;
    line-height: 32px;
    color: #5676da;

    &:after {
        content: "";
        position: absolute;
        transform: rotate(45deg);
        height: 8px;
        width: 8px;
        top: 52%;
        right: 18px;
        margin: -5px 0 0 -5px;
        border: 1px solid #5676da;
        border-width: 1px 1px 0 0;
        margin-left: -8px;
    }
}

.c-button-info,
.c-button-help {
    display: block;
    border: 0;
    background-color: transparent;
    outline: none;

    span {
        @include sr-only;
    }
}
.c-button-info {
    @include sprite-retina($sp_icons_info);
}
.c-button-help {
    @include sprite-retina($sp_eui_info2);
}
